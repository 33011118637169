// ----------------------------------------------------------------Privates---
interface ProductFilterRequestModel {
    id?: number;
    createdAtStart?: Date | null;
    createdAtEnd?: Date | null;
    updatedAtStart?: Date | null;
    updatedAtEnd?: Date | null;
    createdBy?: number;
    updatedBy?: number;
    barcode?: string;
    name?: string;
    groupId?: number;
    vatRateId?: number;
    unitOfMeasureId?: number;
    serviceListNumber?: string;
    netPurchasePrice?: number;
    grossPurchasePrice?: number;
    netSalesPrice?: number;
    grossSalesPrice?: number;
    netWeight?: number;
    grossWeight?: number;
    width?: number;
    height?: number;
    length?: number;
    comment?: string;
    factoryWarranty?: number;
    isMediatedService?: boolean | null;
}

// -----------------------------------------------------------------Exports---
export const initialProductFilterRequestModel: ProductFilterRequestModel = {
    id: -1,
    createdAtStart: null,
    createdAtEnd: null,
    updatedAtStart: null,
    updatedAtEnd: null,
    createdBy: undefined,
    updatedBy: undefined,
    barcode: '',
    name: '',
    groupId: undefined,
    vatRateId: undefined,
    unitOfMeasureId: undefined,
    serviceListNumber: '',
    netPurchasePrice: 0,
    grossPurchasePrice: 0,
    netSalesPrice: 0,
    grossSalesPrice: 0,
    netWeight: -1,
    grossWeight: -1,
    width: -1,
    height: -1,
    length: -1,
    comment: '',
    factoryWarranty: -1,
    isMediatedService: null,
}

export default ProductFilterRequestModel;
