// -----------------------------------------------------------------Imports---
import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

// ------------------------------------------------------------------Render---
createRoot(document.getElementById('root')!)
    .render(
        <StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </StrictMode>
    );

reportWebVitals();
