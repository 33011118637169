// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import UnitOfMeasureEntity from '../../entities/UnitOfMeasureEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import UnitOfMeasureAddOrUpdateRequestModel from '../../models/unitOfMeasure/UnitOfMeasureAddOrUpdateRequestModel';
import UnitOfMeasureListRequestModel from '../../models/unitOfMeasure/UnitOfMeasureListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getUnitOfMeasures(data?: UnitOfMeasureListRequestModel): Promise<ResponseModel<UnitOfMeasureEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.unitOfMeasure.getUnitOfMeasures,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async addUnitOfMeasure(data: UnitOfMeasureAddOrUpdateRequestModel): Promise<ResponseModel<UnitOfMeasureEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.unitOfMeasure.addUnitOfMeasure,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async updateUnitOfMeasure(id: number, data?: UnitOfMeasureAddOrUpdateRequestModel): Promise<ResponseModel<UnitOfMeasureEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.unitOfMeasure.updateUnitOfMeasure.replace('{id}', id.toString()),
            'PUT',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async removeUnitOfMeasure(id: number): Promise<ResponseModel<string>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.unitOfMeasure.removeUnitOfMeasure.replace('{id}', id.toString()),
            'DELETE',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        );
    }
}

const UnitOfMeasureService = new LocalService();

// -----------------------------------------------------------------Exports---
export default UnitOfMeasureService;
