// ----------------------------------------------------------------Privates---
interface ProductAddOrUpdateRequestModel {
    barcode?: string;
    name?: string;
    groupId?: number;
    vatRateId?: number;
    unitOfMeasureId?: number;
    serviceListNumber?: string;
    netPurchasePrice?: number;
    grossPurchasePrice?: number;
    netSalesPrice?: number;
    grossSalesPrice?: number;
    netWeight?: number;
    grossWeight?: number;
    width?: number;
    height?: number;
    length?: number;
    comment?: string;
    factoryWarranty?: number;
    isMediatedService?: boolean;
}

// -----------------------------------------------------------------Exports---
export const initialProductAddOrUpdateRequestModel: ProductAddOrUpdateRequestModel = {
    barcode: '',
    name: '',
    groupId: undefined,
    vatRateId: undefined,
    unitOfMeasureId: undefined,
    serviceListNumber: '',
    netPurchasePrice: 0,
    grossPurchasePrice: 0,
    netSalesPrice: 0,
    grossSalesPrice: 0,
    netWeight: 0,
    grossWeight: 0,
    width: 0,
    height: 0,
    length: 0,
    comment: '',
    factoryWarranty: 0,
    isMediatedService: false,
}

export default ProductAddOrUpdateRequestModel;
