// -----------------------------------------------------------------Imports---
import {
    ChangeEvent,
    createRef,
    Dispatch,
    KeyboardEvent,
    RefObject,
    SetStateAction,
    useCallback,
    useState,
} from 'react';

import {
    NavigateFunction,
    useNavigate,
} from 'react-router-dom';

import { toast } from 'react-toastify';

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    TextField,
    Typography,
} from '@mui/material';

import { getAppRoutes } from '../../Global';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import ResponseModel from '../../models/ResponseModel';
import StringKeyModel from '../../models/StringKeyModel';
import LoginResponseModel from '../../models/authentication/LoginResponseModel';

import AuthenticationService from '../../services/authentication/AuthenticationService';

// ----------------------------------------------------------------Privates---
const inputIds: StringKeyModel = {
    username: 'inputUsername',
    password: 'inputPassword',
}

interface State {
    username: string;
    password: string;
}

const initialState: State = {
    username: '',
    password: '',
}

const LoginPage = (): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const navigate: NavigateFunction = useNavigate();
    const inputPasswordRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);

    const validateData = useCallback((): boolean => {
        if (state.username.length === 0) {
            toast.error('"Felhasználónév / E-mail cím" mező kitöltése kötelező.');
            return false;
        }

        if (state.password.length === 0) {
            toast.error('"Jelszó" mező kitöltése kötelező.');
            return false;
        }

        return true;
    }, [state.password.length, state.username.length]);

    // --------------------------------------------------------------Events---
    const handleChangeUsernameInput = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            username: event.target.value,
        }));
    }, []);

    const handleChangePasswordInput = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            password: event.target.value,
        }));
    }, []);

    const handleClickLoginButton = useCallback(async (): Promise<void> => {
        if (!validateData()) {
            return;
        }

        const response: ResponseModel<LoginResponseModel> = await AuthenticationService.login({
            username: state.username,
            password: state.password,
        });

        if (response.status === 200) {
            toast.success(`Üdvözöljük, ${response.data?.user?.name}!`);
            navigate(getAppRoutes.home);
        } else {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(`${field.field}: ${field.message}`);
                });
            }
        }
    }, [navigate, state.password, state.username, validateData]);

    const handleKeyDownInput = useCallback((event: KeyboardEvent<HTMLInputElement>): void => {
        if (event.key !== 'Enter') {
            return;
        }

        switch ((event.target as HTMLInputElement).id) {
            case inputIds.username: {
                const inputs: HTMLInputElement[] = Array.from(inputPasswordRef.current?.querySelectorAll('input') || []);
                inputs[0] && inputs[0].focus();
                break;
            }
            case inputIds.password: {
                handleClickLoginButton();
                break;
            }
        }
    }, [handleClickLoginButton, inputPasswordRef]);

    // --------------------------------------------------------------Return---
    return (
        <>
            <Card>
                <CardHeader title={'Bejelentkezés'} />
                <CardContent>
                    <Typography>
                        Felhasználónév / E-mail cím
                    </Typography>
                    <TextField autoFocus fullWidth id={inputIds.username} onChange={handleChangeUsernameInput} onKeyDown={handleKeyDownInput} placeholder={'felhasznalonev123 / email@domain.com'} type={'text'} value={state.username} />
                    <Typography>
                        Jelszó
                    </Typography>
                    <TextField fullWidth id={inputIds.password} onChange={handleChangePasswordInput} onKeyDown={handleKeyDownInput} placeholder={'******'} ref={inputPasswordRef} type={'password'} value={state.password} />
                    <Button color={'info'} onClick={handleClickLoginButton} type={'submit'} variant={'contained'}>
                        Bejelentkezés
                    </Button>
                </CardContent>
            </Card>
        </>
    );
}

// -----------------------------------------------------------------Exports---
export default LoginPage;
