// -----------------------------------------------------------------Imports---
import { useMemo } from 'react';

import {
    Location,
    useLocation,
} from 'react-router-dom';

import {
    Grid,
    Typography,
} from '@mui/material';

import { getNameFromRoute } from '../../../CodeKit';

import CSSPropertiesModel from '../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
/**
 * Fej sorhoz középső elemek
 */
const SubMiddleComponent = (): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const { pathname }: Location = useLocation();

    const currentPageName: string = useMemo((): string => {
        return getNameFromRoute(pathname);
    }, [pathname]);

    // --------------------------------------------------------------Styles---
    const gridStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            flexBasis: 'content',
            flexWrap: 'nowrap',
        },
    }

    const typographyStyles: CSSPropertiesModel = {
        main: {
            userSelect: 'none',
            whiteSpace: 'nowrap',
        },
    }

    const typographySx: SxPropsModel = {
        main: {
            display: {
                sm: 'initial',
                xs: 'none',
            },
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Grid
            item container
            style={gridStyles.main}
        >
            <Grid item>
                <Typography
                    style={typographyStyles.main}
                    sx={typographySx.main}
                    variant={'h6'}
                >
                    {currentPageName}
                </Typography>
            </Grid>
        </Grid>
    );
}

// -----------------------------------------------------------------Exports---
export default SubMiddleComponent;
