// ----------------------------------------------------------------Privates---
interface CountryFilterRequestModel {
    id?: number;
    createdAtStart?: Date | null;
    createdAtEnd?: Date | null;
    updatedAtStart?: Date | null;
    updatedAtEnd?: Date | null;
    createdBy?: number;
    updatedBy?: number;
    name?: string;
    isoCode?: string;
}

// -----------------------------------------------------------------Exports---
export const initialCountryFilterRequestModel: CountryFilterRequestModel = {
    id: -1,
    createdAtStart: null,
    createdAtEnd: null,
    updatedAtStart: null,
    updatedAtEnd: null,
    createdBy: undefined,
    updatedBy: undefined,
    name: '',
    isoCode: '',
}

export default CountryFilterRequestModel;
