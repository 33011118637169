// -----------------------------------------------------------------Imports---
import {
    Dispatch,
    Key,
    SetStateAction,
    useCallback,
    useState,
} from 'react';

import { ExpandMore } from '@mui/icons-material';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Typography,
} from '@mui/material';

import GroupEntity from '../../../../entities/GroupEntity';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackGroupFilter: (value: number | undefined) => void;
    filteredGroupId: number | undefined;
    groups: GroupEntity[];
}

/**
 * Csoport szűrő
 * @param callbackGroupFilter Csoport esemény
 * @param filteredGroupId Kiválasztott csoport azonosító
 * @param groups Csoportok
 */
const SubGroupListComponent = ({
    callbackGroupFilter,
    filteredGroupId,
    groups,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const [isExpanded, setIsExpanded]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);

    // --------------------------------------------------------------Events---
    const handleChangeAccordion = useCallback((): void => {
        setIsExpanded((prevIsExpanded: boolean): boolean => !prevIsExpanded);
    }, []);

    const handleClickGroupFilter = useCallback((value: number | undefined): void => {
        callbackGroupFilter(value);
        setIsExpanded(false);
    }, [callbackGroupFilter]);

    // --------------------------------------------------------------Styles---
    const accordionStyles: CSSPropertiesModel = {
        main: {
            marginBottom: 0,
        },
    }

    const buttonStyles: CSSPropertiesModel = {
        main: {
            height: '100%',
            paddingBottom: 15,
            paddingTop: 15,
            wordBreak: 'break-word',
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Accordion expanded={isExpanded} onChange={handleChangeAccordion} style={accordionStyles.main}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                    Csoportok
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid
                    item container
                    spacing={1}
                >
                    {groups.map((group: GroupEntity, groupKey: Key): JSX.Element => {
                        return (
                            <Grid
                                item
                                key={groupKey}
                                lg={3} md={4} sm={6} xs={12}
                            >
                                <Button
                                    color={group.id === filteredGroupId ? 'success' : 'info'}
                                    fullWidth
                                    onClick={(): void => handleClickGroupFilter(group.id)}
                                    style={buttonStyles.main}
                                    variant={group.id === filteredGroupId ? 'contained' : 'outlined'}
                                >
                                    {group.name}
                                </Button>
                            </Grid>
                        );
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion >
    );
}

// -----------------------------------------------------------------Exports---
export default SubGroupListComponent;
