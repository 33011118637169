// -----------------------------------------------------------------Imports---
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
} from 'react';

import { Menu } from '@mui/icons-material';

import {
    Grid,
    IconButton,
    Tooltip,
    Typography,
    Zoom,
} from '@mui/material';

import { isTest } from '../../../Global';

import CSSPropertiesModel from '../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackIsDrawerOpened: Dispatch<SetStateAction<boolean>>;
}

/**
 * FIXME: Dispatch helyett callback-et kell használni
 * Fej sorhoz bal oldali elemek
 * @param callbackIsDrawerOpened Navigáció megjelenítés állapot esemény
 */
const SubLeftSideComponent = ({ callbackIsDrawerOpened, }: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const isTestModeMark: string = useMemo((): string => {
        return isTest ? '[TESZT]' : '';
    }, []);

    // --------------------------------------------------------------Events---
    const handleClickMenuOpen = useCallback((): void => {
        callbackIsDrawerOpened((prevOpen: boolean): boolean => !prevOpen);
    }, [callbackIsDrawerOpened]);

    // --------------------------------------------------------------Styles---
    const gridStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            flexBasis: 'content',
            flexWrap: 'nowrap',
        },
    }

    const typographyStyles: CSSPropertiesModel = {
        main: {
            userSelect: 'none',
            whiteSpace: 'nowrap',
        },
    }

    const typographySx: SxPropsModel = {
        main: {
            display: {
                md: 'initial',
                xs: 'none',
            },
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Grid
            item container
            style={gridStyles.main}
        >
            <Grid item>
                <Tooltip
                    arrow
                    placement={'bottom'}
                    title={'Menü'}
                    TransitionComponent={Zoom}
                >
                    <IconButton
                        aria-label={'open drawer'}
                        edge={'start'}
                        onClick={handleClickMenuOpen}
                    >
                        <Menu />
                    </IconButton>
                </Tooltip>
            </Grid>

            <Grid item>
                <Typography
                    style={typographyStyles.main}
                    sx={typographySx.main}
                    variant={'h6'}
                >
                    {`SziHome StockWatch ${isTestModeMark}`}
                </Typography>
            </Grid>
        </Grid>
    );
}

// -----------------------------------------------------------------Exports---
export default SubLeftSideComponent;
