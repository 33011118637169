// -----------------------------------------------------------------Imports---
import {
    DialogContent,
    DialogContentText,
} from '@mui/material';

import DialogTypeEnumerator from '../../../enumerators/DialogTypeEnumerator';

import CSSPropertiesModel from '../../../models/CSSPropertiesModel';

// ----------------------------------------------------------------Privates---
interface Props {
    dataToBeRemoved: string;
    fields: JSX.Element;
    type: DialogTypeEnumerator;
}

/**
 * Általános felugró 'Dialog' ablakhoz törzs sor
 * @param dataToBeRemoved Törlési üzem esetén törlendő adat
 * @param fields Hozzáadási, módosítási üzem esetén kitöltendő komponensek
 * @param type 'Dialog' típus
 */
const SubDialogContentComponent = ({
    dataToBeRemoved,
    fields,
    type,
}: Props): JSX.Element => {
    // --------------------------------------------------------------Styles---
    const dialogContentStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
        },
    }

    const dialogContentTextStyles: CSSPropertiesModel = {
        deleted: {
            color: 'crimson',
            fontWeight: 'bold',
        },
        main: {
            textAlign: 'justify',
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <DialogContent style={dialogContentStyles.main}>
            {type === DialogTypeEnumerator.Remove ? (
                <DialogContentText style={dialogContentTextStyles.main}>
                    Biztos törlöd a megjelölt adatot: "
                    <span style={dialogContentTextStyles.deleted}>
                        {dataToBeRemoved}
                    </span>
                    "?
                </DialogContentText>
            ) : (
                <>
                    {fields}
                </>
            )}
        </DialogContent>
    );
}

// -----------------------------------------------------------------Exports---
export default SubDialogContentComponent;
