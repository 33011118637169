// -----------------------------------------------------------------Imports---
import {
    Dispatch,
    SetStateAction,
    SyntheticEvent,
    useCallback,
    useState,
} from 'react';

import {
    Close,
    Save,
} from '@mui/icons-material';

import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Theme,
    useTheme,
} from '@mui/material';

import DialogTransitionComponent from '../../../../components/dialog/DialogTransitionComponent';

import PaymentMethodEntity from '../../../../entities/PaymentMethodEntity';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackIsOpened: (value: boolean) => void;
    callbackSelectedPaymentMethod: (value: PaymentMethodEntity | null) => void;
    isOpened: boolean;
    paymentMethods: PaymentMethodEntity[];
    selectedPaymentMethod: PaymentMethodEntity | null;
}

/**
 * Számla fizetési mód
 * @param callbackIsOpened Nyitott állapot esemény
 * @param callbackSelectedPaymentMethod Kiválasztott fizetési mód esemény
 * @param isOpened Nyitott állapot
 * @param paymentMethods Fizetési módok
 * @param selectedPaymentMethod Kiválasztott fizetési mód
 */
const SubDialogPaymentMethodComponent = ({
    callbackIsOpened,
    callbackSelectedPaymentMethod,
    isOpened,
    paymentMethods,
    selectedPaymentMethod,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [thisPaymentMethod, setThisPaymentMethod]: [PaymentMethodEntity | null, Dispatch<SetStateAction<PaymentMethodEntity | null>>] = useState<PaymentMethodEntity | null>(selectedPaymentMethod);

    // --------------------------------------------------------------Events---
    const handleChangePaymentMethod = useCallback((event: SyntheticEvent, newValue: PaymentMethodEntity | null): void => {
        setThisPaymentMethod(newValue);
    }, []);

    const handleClickClose = useCallback((): void => {
        setThisPaymentMethod(selectedPaymentMethod);
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        selectedPaymentMethod,
    ]);

    const handleClickSave = useCallback((): void => {
        if (thisPaymentMethod) {
            callbackSelectedPaymentMethod(thisPaymentMethod);
        }
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        callbackSelectedPaymentMethod,
        thisPaymentMethod,
    ]);

    // --------------------------------------------------------------Styles---
    const dialogActionsStyles: CSSPropertiesModel = {
        main: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }

    const dialogContentStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
        },
    }

    const dialogTitleStyles: CSSPropertiesModel = {
        main: {
            textAlign: 'center',
            userSelect: 'none',
        },
    }

    const paperStyles: CSSPropertiesModel = {
        main: {
            maxWidth: 'initial',
        },
    }

    const paperSx: SxPropsModel = {
        main: {
            width: '50%',
            [theme.breakpoints.down('lg')]: {
                width: '75%',
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Dialog
            open={isOpened}
            PaperProps={{
                style: paperStyles.main,
                sx: paperSx.main,
            }}
            TransitionComponent={DialogTransitionComponent}
        >
            <DialogTitle style={dialogTitleStyles.main}>
                Fizetési mód kiválasztás
            </DialogTitle>
            <Divider variant={'middle'} />
            <DialogContent style={dialogContentStyles.main}>
                <Autocomplete
                    autoComplete
                    filterSelectedOptions
                    fullWidth
                    getOptionLabel={(option: PaymentMethodEntity) => `${option.name} (${option.id?.toString()})` ?? ''}
                    onChange={handleChangePaymentMethod}
                    options={paymentMethods}
                    renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Fizetési módok'} />}
                    value={thisPaymentMethod}
                />
            </DialogContent>
            <Divider variant={'middle'} />
            <DialogActions style={dialogActionsStyles.main}>
                <Button
                    onClick={handleClickClose}
                    startIcon={<Close />}
                    variant={'outlined'}
                >
                    Mégsem
                </Button>
                <Button
                    color={'success'}
                    disabled={!thisPaymentMethod}
                    onClick={handleClickSave}
                    startIcon={<Save />}
                    variant={'contained'}
                >
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// -----------------------------------------------------------------Exports---
export default SubDialogPaymentMethodComponent;
