// -----------------------------------------------------------------Imports---
import {
    menus,
    roundingDigits,
} from './Global';
import DeliveryAddressEntity from './entities/DeliveryAddressEntity';
import PartnerEntity from './entities/PartnerEntity';

import MenuModel from './models/MenuModel';

// -----------------------------------------------------------------Exports---
/**
 * Útvonalból megnevezés
 * @param route Alkalmazás útvonal
 */
export const getNameFromRoute = (route: string): string => {
    const matchedMenu: MenuModel | undefined = menus.find((menu: MenuModel): boolean | undefined => {
        return menu.route === route || menu.subMenu?.some((subMenu: MenuModel): boolean => {
            return subMenu.route === route;
        });
    });

    if (!matchedMenu) {
        return '';
    }

    const matchedSubMenu: MenuModel | undefined = matchedMenu.subMenu?.find((subMenu: MenuModel): boolean => {
        return subMenu.route === route;
    })

    const displaySubMenu: string = matchedSubMenu?.name
        ? ` / ${matchedSubMenu.name}`
        : '';

    return `${matchedMenu.name}${displaySubMenu}`;
}

/**
 * Nettóból bruttó
 * @param net Nettó érték
 * @param vatValue ÁFA érték
 */
export const grossFromNet = (net?: number, vatValue?: number): number => {
    if (
        (!net)
        || (net < 0)
        || (net === Infinity)
    ) {
        net = 0;
    }

    if (
        (!vatValue)
        || (vatValue < 0)
        || (vatValue === Infinity)
    ) {
        vatValue = 0;
    }

    return net * (1 + (vatValue / 100));
}

/**
 * Tömb rendező
 * @param valueA Első érték
 * @param valueB Második érték
 */
export const nameSort = (valueA: string, valueB: string): number => {
    if (valueA === valueB) {
        return 0;
    } else if (valueA < valueB) {
        return -1;
    } else {
        return 1;
    }
}

/**
 * Bruttóból nettó
 * @param gross Bruttó érték
 * @param vatValue ÁFA érték
 */
export const netFromGross = (gross?: number, vatValue?: number): number => {
    if (
        (!gross)
        || (gross < 0)
        || (gross === Infinity)
    ) {
        gross = 0;
    }

    if (
        (!vatValue)
        || (vatValue < 0)
        || (vatValue === Infinity)
    ) {
        vatValue = 0;
    }

    return gross / (1 + (vatValue / 100));
}

/**
 * Szám validátor
 * @param value Validálandó érték
 */
export const numberInStringIsValid = (value: string): boolean => {
    return !isNaN(Number(value));
}

/**
 * Teljes cím generátor partner vagy telephely szerint
 * @param value Partner, telephely
 */
export const partnerOrDeliveryAddressToFullAddress = (value: PartnerEntity | DeliveryAddressEntity) => {
    let result = '';

    if (value.postCode)
        result += value.postCode;

    if ((value.postCode) && (value.city))
        result += ' ';

    if (value.city)
        result += value.city;

    if ((value.city) && (value.streetName))
        result += ', ';

    if (value.streetName)
        result += value.streetName;

    if ((value.streetName) && (value.streetType))
        result += ' ';

    if (value.streetType)
        result += value.streetType;

    if ((value.streetType) && (value.houseNumber))
        result += ' ';

    if (value.houseNumber)
        result += value.houseNumber;

    return result;
}

/**
 * Kerekítés
 * @param value Érték
 */
export const roundTo = (value: number): number => {
    const factor: number = 10 ** roundingDigits;

    return Math.round(value * factor) / factor;
}

/**
 * Bruttóból ÁFA
 * @param gross Bruttó érték
 * @param vatValue ÁFA érték
 */
export const vatFromGross = (gross?: number, vatValue?: number): number => {
    if (
        (!gross)
        || (gross < 0)
        || (gross === Infinity)
    ) {
        gross = 0;
    }

    if (
        (!vatValue)
        || (vatValue < 0)
        || (vatValue === Infinity)
    ) {
        vatValue = 0;
    }

    return gross * (vatValue / 100);
}
