// -----------------------------------------------------------------Imports---
import {
    Dispatch,
    SetStateAction,
    SyntheticEvent,
    useCallback,
    useState,
} from 'react';

import {
    Close,
    Save,
} from '@mui/icons-material';

import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Theme,
    useTheme,
} from '@mui/material';

import DialogTransitionComponent from '../../../../components/dialog/DialogTransitionComponent';

import DeliveryMethodEntity from '../../../../entities/DeliveryMethodEntity';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackIsOpened: (value: boolean) => void;
    callbackSelectedDeliveryMethod: (value: DeliveryMethodEntity | null) => void;
    deliveryMethods: DeliveryMethodEntity[];
    isOpened: boolean;
    selectedDeliveryMethod: DeliveryMethodEntity | null;
}

/**
 * Számla szállítási mód
 * @param callbackIsOpened Nyitott állapot esemény
 * @param callbackSelectedDeliveryMethod Kiválasztott szállítási mód esemény
 * @param deliveryMethods Szállítási módok
 * @param isOpened Nyitott állapot
 * @param selectedDeliveryMethod Kiválasztott szállítási mód
 */
const SubDialogDeliveryMethodComponent = ({
    callbackIsOpened,
    callbackSelectedDeliveryMethod,
    deliveryMethods,
    isOpened,
    selectedDeliveryMethod,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [thisDeliveryMethod, setThisDeliveryMethod]: [DeliveryMethodEntity | null, Dispatch<SetStateAction<DeliveryMethodEntity | null>>] = useState<DeliveryMethodEntity | null>(selectedDeliveryMethod);

    // --------------------------------------------------------------Events---
    const handleChangeDeliveryMethod = useCallback((event: SyntheticEvent, newValue: DeliveryMethodEntity | null): void => {
        setThisDeliveryMethod(newValue);
    }, []);

    const handleClickClose = useCallback((): void => {
        setThisDeliveryMethod(selectedDeliveryMethod);
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        selectedDeliveryMethod,
    ]);

    const handleClickSave = useCallback((): void => {
        if (thisDeliveryMethod) {
            callbackSelectedDeliveryMethod(thisDeliveryMethod);
        }
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        callbackSelectedDeliveryMethod,
        thisDeliveryMethod,
    ]);

    // --------------------------------------------------------------Styles---
    const dialogActionsStyles: CSSPropertiesModel = {
        main: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }

    const dialogContentStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
        },
    }

    const dialogTitleStyles: CSSPropertiesModel = {
        main: {
            textAlign: 'center',
            userSelect: 'none',
        },
    }

    const paperStyles: CSSPropertiesModel = {
        main: {
            maxWidth: 'initial',
        },
    }

    const paperSx: SxPropsModel = {
        main: {
            width: '50%',
            [theme.breakpoints.down('lg')]: {
                width: '75%',
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Dialog
            open={isOpened}
            PaperProps={{
                style: paperStyles.main,
                sx: paperSx.main,
            }}
            TransitionComponent={DialogTransitionComponent}
        >
            <DialogTitle style={dialogTitleStyles.main}>
                Szállítási mód kiválasztás
            </DialogTitle>
            <Divider variant={'middle'} />
            <DialogContent style={dialogContentStyles.main}>
                <Autocomplete
                    autoComplete
                    filterSelectedOptions
                    fullWidth
                    getOptionLabel={(option: DeliveryMethodEntity) => `${option.name} [${option.price} Ft] (${option.id?.toString()})` ?? ''}
                    onChange={handleChangeDeliveryMethod}
                    options={deliveryMethods}
                    renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Szállítási módok'} />}
                    value={thisDeliveryMethod}
                />
            </DialogContent>
            <Divider variant={'middle'} />
            <DialogActions style={dialogActionsStyles.main}>
                <Button
                    onClick={handleClickClose}
                    startIcon={<Close />}
                    variant={'outlined'}
                >
                    Mégsem
                </Button>
                <Button
                    color={'success'}
                    disabled={!thisDeliveryMethod}
                    onClick={handleClickSave}
                    startIcon={<Save />}
                    variant={'contained'}
                >
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// -----------------------------------------------------------------Exports---
export default SubDialogDeliveryMethodComponent;
