// -----------------------------------------------------------------Imports---
import {
    Dispatch,
    Key,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';

import {
    Location,
    NavigateFunction,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    ExpandLess,
    ExpandMore,
    Remove,
} from '@mui/icons-material';

import {
    Collapse,
    CSSObject,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Theme,
    Tooltip,
    useTheme,
    Zoom,
} from '@mui/material';

import MuiDrawer from '@mui/material/Drawer';

import { menus } from '../../Global';

import CSSPropertiesModel from '../../models/CSSPropertiesModel';
import MenuModel from '../../models/MenuModel';
import SxPropsModel from '../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
let drawerWidth: number = 0;

const openedMixin = (theme: Theme): CSSObject => ({
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
    }),
    width: drawerWidth,
});

const closedMixin = (theme: Theme): CSSObject => ({
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
    }),
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop: PropertyKey): boolean => prop !== 'open' })(({ theme, open }) => ({
    boxSizing: 'border-box',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: drawerWidth,
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

interface Props {
    callbackIsOpened: Dispatch<SetStateAction<boolean>>;
    isOpened: boolean;
    width: number;
}

interface State {
    selectedMenuIndex: number;
}

const initialState: State = {
    selectedMenuIndex: -1,
}

/**
 * Navigáció
 * FIXME: Dispatch helyett callback-et kell használni
 * @param callbackIsOpened Navigáció nyitott állapot esemény
 * @param isOpened Navigáció nyitott állapot
 * @param width Maximális szélesség nyitott állapotban
 */
const DrawerComponent = ({
    callbackIsOpened,
    isOpened,
    width
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);

    const { pathname }: Location = useLocation();
    const navigate: NavigateFunction = useNavigate();
    const theme: Theme = useTheme<Theme>();

    useEffect((): void => {
        drawerWidth = width;
    }, [width]);

    // --------------------------------------------------------------Events---
    const handleClickMenu = useCallback((index: number, route?: string): void => {
        setState((prevState: State): State => ({
            ...prevState,
            selectedMenuIndex: index !== state.selectedMenuIndex
                ? index
                : !isOpened
                    ? state.selectedMenuIndex
                    : -1,
        }));

        if (!isOpened) {
            callbackIsOpened(true);
        }

        if (route !== undefined) {
            callbackIsOpened(false);
            navigate(route);
        }
    }, [navigate, isOpened, callbackIsOpened, state.selectedMenuIndex]);

    const handleClickSubMenu = useCallback((route: string): void => {
        callbackIsOpened(false);
        navigate(route);
    }, [navigate, callbackIsOpened]);

    // --------------------------------------------------------------Styles---
    const listSx = (theme: Theme): SxPropsModel => {
        return {
            main: {
                marginTop: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    marginTop: theme.spacing(8),
                },
            },
        }
    }

    const listItemStyles: CSSPropertiesModel = {
        main: {
            display: 'block',
        },
    }

    const listItemButtonSx: SxPropsModel = {
        main: {
            minHeight: 48,
            px: 2.5,
        },
    }

    const listItemIconStyles: CSSPropertiesModel = {
        main: {
            minWidth: 0,
        },
    }

    const listItemIconSx = (isOpened: boolean): SxPropsModel => {
        return {
            main: {
                mr: isOpened ? 2.5 : 'auto',
            },
            sub: {
                mr: 2.5,
            },
        }
    }

    const listItemTextStyles = (isOpened: boolean): CSSPropertiesModel => {
        return {
            main: {
                display: isOpened ? 'initial' : 'none',
            }
        }
    }

    // --------------------------------------------------------------Return---
    return (
        <Drawer
            open={isOpened}
            variant={'permanent'}
        >
            <List
                component={'nav'}
                disablePadding
                key={'menu'}
                sx={listSx(theme).main}
            >
                {menus.map((menu: MenuModel, menuKey: Key): JSX.Element => {
                    return (
                        <ListItem
                            disablePadding
                            key={menuKey}
                            style={listItemStyles.main}
                        >
                            <Tooltip
                                arrow
                                placement={'right'}
                                title={menu.name}
                                TransitionComponent={Zoom}
                            >
                                <ListItemButton
                                    onClick={(): void => handleClickMenu(Number(menuKey), menu.route!)}
                                    selected={(menu.route !== undefined) && (pathname === menu.route)}
                                    sx={listItemButtonSx.main}
                                >
                                    <ListItemIcon
                                        style={listItemIconStyles.main}
                                        sx={listItemIconSx(isOpened).main}
                                    >
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={menu.name}
                                        style={listItemTextStyles(isOpened).main}
                                    />
                                    {(isOpened) && (menu.route === undefined) && (
                                        (menu.subMenu === undefined)
                                            ? <Remove />
                                            : (menuKey === state.selectedMenuIndex)
                                                ? <ExpandLess />
                                                : <ExpandMore />
                                    )}
                                </ListItemButton>
                            </Tooltip>

                            <Collapse
                                in={(isOpened) && (menuKey === state.selectedMenuIndex)}
                                timeout={'auto'}
                                unmountOnExit
                            >
                                <List
                                    disablePadding
                                    key={`subMenu${menuKey}`}
                                >
                                    {(menu.subMenu !== undefined) && menu.subMenu!.map((subMenu: MenuModel, subMenuKey: Key): JSX.Element => {
                                        return (
                                            <ListItem
                                                disablePadding
                                                key={subMenuKey}
                                                style={listItemStyles.main}
                                            >
                                                <Tooltip
                                                    arrow
                                                    placement={'right'}
                                                    title={`${menu.name} / ${subMenu.name}`}
                                                    TransitionComponent={Zoom}
                                                >
                                                    <ListItemButton
                                                        onClick={(): void => handleClickSubMenu(subMenu.route!)}
                                                        selected={pathname === subMenu.route}
                                                        sx={{ pl: 5, }}
                                                    >
                                                        <ListItemIcon
                                                            style={listItemIconStyles.main}
                                                            sx={listItemIconSx(isOpened).main}
                                                        >
                                                            {subMenu.icon}
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={subMenu.name}
                                                            style={listItemTextStyles(isOpened).main}
                                                        />
                                                    </ListItemButton>
                                                </Tooltip>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Collapse>
                        </ListItem>
                    );
                })}
            </List>
        </Drawer>
    );
}

// -----------------------------------------------------------------Exports---
export default DrawerComponent;
