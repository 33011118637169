// ----------------------------------------------------------------Privates---
interface UnitOfMeasureAddOrUpdateRequestModel {
    shortName?: string;
    longName?: string;
    navName?: string | null;
    isDecompasable?: boolean;
}

// -----------------------------------------------------------------Exports---
export const initialUnitOfMeasureAddOrUpdateRequestModel: UnitOfMeasureAddOrUpdateRequestModel = {
    shortName: '',
    longName: '',
    navName: null,
    isDecompasable: false,
}

export default UnitOfMeasureAddOrUpdateRequestModel;
