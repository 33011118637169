// ----------------------------------------------------------------Privates---
interface PartnerFilterRequestModel {
    id?: number;
    createdAtStart?: Date | null;
    createdAtEnd?: Date | null;
    updatedAtStart?: Date | null;
    updatedAtEnd?: Date | null;
    createdBy?: number;
    updatedBy?: number;
    name?: string;
    postCode?: string;
    city?: string;
    streetName?: string;
    streetType?: string;
    houseNumber?: string;
    taxNumber?: string;
    bankAccountNumber?: string;
    telephone?: string;
    email?: string;
    contactName?: string;
    contactTelephone?: string;
    contactEmail?: string;
    comment?: string;
    countryId?: number;
    type?: string;
}

// -----------------------------------------------------------------Exports---
export const initialPartnerFilterRequestModel: PartnerFilterRequestModel = {
    id: -1,
    createdAtStart: null,
    createdAtEnd: null,
    updatedAtStart: null,
    updatedAtEnd: null,
    createdBy: undefined,
    updatedBy: undefined,
    name: '',
    postCode: '',
    city: '',
    streetName: '',
    streetType: '',
    houseNumber: '',
    taxNumber: '',
    bankAccountNumber: '',
    telephone: '',
    email: '',
    contactName: '',
    contactTelephone: '',
    contactEmail: '',
    comment: '',
    countryId: undefined,
    type: undefined,
}

export default PartnerFilterRequestModel;
