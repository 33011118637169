// -----------------------------------------------------------------Imports---
import {
    Grid,
    TextField,
} from '@mui/material';

import { SaleItem } from '../SaleInvoicePage';

import {
    netFromGross,
    roundTo,
    vatFromGross,
} from '../../../../CodeKit';

import DeliveryMethodEntity from '../../../../entities/DeliveryMethodEntity';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';

// ----------------------------------------------------------------Privates---
interface Props {
    selectedDeliveryMethod: DeliveryMethodEntity | null;
    selectedSaleItems: SaleItem[];
}

/**
 * Kiválasztott számla termékek összegző
 * @param selectedDeliveryMethod Kiválasztott szállítási mód
 * @param selectedSaleItems Kiválasztott termékek
 */
const SubSaleItemsSummaryComponent = ({
    selectedDeliveryMethod,
    selectedSaleItems,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const getHighestVatRateFromSaleItems = (): number => {
        return Math.max(
            ...selectedSaleItems.map((item: SaleItem): number => {
                if (item.product.vatRate?.value) {
                    return item.product.vatRate?.value;
                }

                return 0;
            })
        );
    }

    // --------------------------------------------------------------Styles---
    const gridStyles: CSSPropertiesModel = {
        center: {
            paddingLeft: 4,
            paddingRight: 4,
        },
        left: {
            paddingRight: 4,
        },
        right: {
            paddingLeft: 4,
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Grid item container>
            <Grid
                item
                style={gridStyles.left}
                xs={4}
            >
                <TextField
                    fullWidth
                    InputProps={{ readOnly: true, }}
                    label={'Nettó összes'}
                    value={roundTo(selectedSaleItems.reduce((total: number, item: SaleItem): number =>
                        total + Number(item.product?.netSalesPrice) * item.quantity,
                        selectedDeliveryMethod?.price
                            ? netFromGross(selectedDeliveryMethod.price, getHighestVatRateFromSaleItems())
                            : 0
                    )).toLocaleString()}
                />
            </Grid>
            <Grid
                item
                style={gridStyles.center}
                xs={4}
            >
                <TextField
                    fullWidth
                    InputProps={{ readOnly: true, }}
                    label={'ÁFA összes'}
                    value={roundTo(selectedSaleItems.reduce((total: number, item: SaleItem): number =>
                        total + (Number(item.product?.grossSalesPrice) - Number(item.product?.netSalesPrice)) * item.quantity,
                        selectedDeliveryMethod?.price
                            ? vatFromGross(selectedDeliveryMethod.price, getHighestVatRateFromSaleItems())
                            : 0
                    )).toLocaleString()}
                />
            </Grid>
            <Grid
                item
                style={gridStyles.right}
                xs={4}
            >
                <TextField
                    fullWidth
                    InputProps={{ readOnly: true, }}
                    label={'Bruttó összes'}
                    value={roundTo(selectedSaleItems.reduce((total: number, item: SaleItem): number =>
                        total + Number(item.product?.grossSalesPrice) * item.quantity,
                        selectedDeliveryMethod?.price
                            ? selectedDeliveryMethod?.price
                            : 0
                    )).toLocaleString()}
                />
            </Grid>
        </Grid>
    );
}

// -----------------------------------------------------------------Exports---
export default SubSaleItemsSummaryComponent;
