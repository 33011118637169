// ----------------------------------------------------------------Privates---
interface CountryAddOrUpdateRequestModel {
    name?: string;
    isoCode?: string;
}

// -----------------------------------------------------------------Exports---
export const initialCountryAddOrUpdateRequestModel: CountryAddOrUpdateRequestModel = {
    name: '',
    isoCode: '',
}

export default CountryAddOrUpdateRequestModel;
