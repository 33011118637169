// -----------------------------------------------------------------Imports---
import jwtDecode from 'jwt-decode';

import {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
} from 'react';

import {
    NavigateFunction,
    useNavigate,
} from 'react-router-dom';

import { toast } from 'react-toastify';

import {
    DarkMode,
    LightMode,
    Logout,
} from '@mui/icons-material';

import {
    Avatar,
    Grid,
    IconButton,
    Tooltip,
    Zoom,
} from '@mui/material';

import { getAppRoutes } from '../../../Global';

import CSSPropertiesModel from '../../../models/CSSPropertiesModel';

import AuthenticationService from '../../../services/authentication/AuthenticationService';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackDarkMode: Dispatch<SetStateAction<boolean>>;
    isDarkMode: boolean;
}

/**
 * FIXME: Dispatch helyett callback-et kell használni
 * Fej sorhoz jobb oldali elemek
 * @param callbackDarkMode Téma állapot esemény
 * @param isDarkMode Alkalmazás téma állapot
 */
const SubRightSideComponent = ({
    callbackDarkMode,
    isDarkMode,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const navigate: NavigateFunction = useNavigate();

    const isDarkModeMark: string = useMemo((): string => {
        return isDarkMode ? 'Világos mód' : 'Sötét mód';
    }, [isDarkMode]);

    const loggedInName: string = useMemo((): string => {
        return (jwtDecode(localStorage.getItem('token')!) as any).name;
    }, []);

    // --------------------------------------------------------------Events---
    const handleClickDarkMode = useCallback((): void => {
        callbackDarkMode((prevIsDarkMode: boolean): boolean => !prevIsDarkMode);
    }, [callbackDarkMode]);

    const handleClickLogout = useCallback((): void => {
        AuthenticationService.logout();
        toast.success('Sikeres kijelentkezés.');
        navigate(getAppRoutes.login);
    }, [navigate]);

    const handleClickUser = useCallback((): void => {
        navigate(getAppRoutes.user);
    }, [navigate]);

    // --------------------------------------------------------------Styles---
    const gridStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            flexBasis: 'content',
            flexWrap: 'nowrap',
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Grid
            item container
            style={gridStyles.main}
        >
            <Grid item>
                <Tooltip
                    arrow
                    placement={'bottom'}
                    title={isDarkModeMark}
                    TransitionComponent={Zoom}
                >
                    <IconButton onClick={handleClickDarkMode}>
                        {isDarkMode
                            ? <DarkMode />
                            : <LightMode />}
                    </IconButton>
                </Tooltip>
            </Grid>

            <Grid item>
                <Tooltip
                    arrow
                    placement={'bottom'}
                    title={'Kijelentkezés'}
                    TransitionComponent={Zoom}
                >
                    <IconButton onClick={handleClickLogout}>
                        <Logout />
                    </IconButton>
                </Tooltip>
            </Grid>

            <Grid item>
                <Tooltip
                    arrow
                    placement={'bottom'}
                    title={loggedInName}
                    TransitionComponent={Zoom}
                >
                    <IconButton onClick={handleClickUser}>
                        <Avatar
                            alt={loggedInName}
                            src={'/'}
                        />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
}

// -----------------------------------------------------------------Exports---
export default SubRightSideComponent;
