// -----------------------------------------------------------------Imports---
import { useMemo } from 'react';

import {
    Close,
    Save,
} from '@mui/icons-material';

import {
    Button,
    DialogActions,
} from '@mui/material';

import DialogTypeEnumerator from '../../../enumerators/DialogTypeEnumerator';

import CSSPropertiesModel from '../../../models/CSSPropertiesModel';

// ----------------------------------------------------------------Privates---
type ButtonColorProp = 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';

interface Props {
    callbackClickClose: () => void;
    callbackClickSave: () => void;
    type: DialogTypeEnumerator;
}

/**
 * Általános felugró 'Dialog' ablakhoz gombsor
 * @param callbackClickClose 'Dialog' bezárás esemény
 * @param callbackClickSave 'Dialog' mentés esemény
 * @param type 'Dialog' típus
 */
const SubDialogActionsComponent = ({
    callbackClickClose,
    callbackClickSave,
    type,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const saveButtonColor: ButtonColorProp = useMemo((): ButtonColorProp => {
        return type === DialogTypeEnumerator.Remove ? 'error' : 'success';
    }, [type]);

    // --------------------------------------------------------------Styles---
    const dialogActionsStyles: CSSPropertiesModel = {
        main: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <DialogActions style={dialogActionsStyles.main}>
            <Button
                onClick={callbackClickClose}
                startIcon={<Close />}
                variant={'outlined'}
            >
                Mégsem
            </Button>
            <Button
                color={saveButtonColor}
                onClick={callbackClickSave}
                startIcon={<Save />}
                variant={'contained'}
            >
                {type.toString()}
            </Button>
        </DialogActions>
    );
}

// -----------------------------------------------------------------Exports---
export default SubDialogActionsComponent;
