// ----------------------------------------------------------------Privates---
interface ServiceListFilterRequestModel {
    id?: number;
    createdAtStart?: Date | null;
    createdAtEnd?: Date | null;
    updatedAtStart?: Date | null;
    updatedAtEnd?: Date | null;
    createdBy?: number;
    updatedBy?: number;
    barcode?: string;
    name?: string;
    serviceListNumber?: string;
    groupId?: number;
    vatRateId?: number;
    unitOfMeasureId?: number;
    netSalesPrice?: number;
    grossSalesPrice?: number;
    isMediatedService?: boolean | null;
}

// -----------------------------------------------------------------Exports---
export const initialServiceListFilterRequestModel: ServiceListFilterRequestModel = {
    id: -1,
    createdAtStart: null,
    createdAtEnd: null,
    updatedAtStart: null,
    updatedAtEnd: null,
    createdBy: undefined,
    updatedBy: undefined,
    barcode: '',
    name: '',
    serviceListNumber: '',
    groupId: undefined,
    vatRateId: undefined,
    unitOfMeasureId: undefined,
    netSalesPrice: 0,
    grossSalesPrice: 0,
    isMediatedService: null,
}

export default ServiceListFilterRequestModel;
