// -----------------------------------------------------------------Imports---
import moment from 'moment';

import {
    ChangeEvent,
    Dispatch,
    Key,
    MouseEvent,
    SetStateAction,
    SyntheticEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { toast } from 'react-toastify';

import {
    DebouncedState,
    useDebounce,
} from 'use-debounce';

import {
    Add,
    Delete,
    ExpandMore,
    Settings,
} from '@mui/icons-material';

import {
    DateTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    AutocompleteRenderInputParams,
    Chip,
    Fab,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';

import {
    fabStyles,
    PaperSxProps,
    TableRowSxProps,
} from './ProductsPage.style';

import {
    grossFromNet,
    netFromGross,
    numberInStringIsValid,
    roundTo,
} from '../../CodeKit';

import {
    dateTimeFormat,
    dateTimePickerInputFormats,
    debounceDelay,
} from '../../Global';

import DialogComponent from '../../components/dialog/DialogComponent';

import GroupEntity from '../../entities/GroupEntity';
import ProductEntity from '../../entities/ProductEntity';
import UnitOfMeasureEntity from '../../entities/UnitOfMeasureEntity';
import UserEntity from '../../entities/UserEntity';
import VatRateEntity from '../../entities/VatRateEntity';

import DialogTypeEnumerator from '../../enumerators/DialogTypeEnumerator';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import NamedBooleanModel from '../../models/NamedBooleanModel';
import PaginationRequestModel from '../../models/PaginationRequestModel';
import ResponseModel from '../../models/ResponseModel';
import TableColumnModel from '../../models/TableColumnModel';
import ProductAddOrUpdateRequestModel, { initialProductAddOrUpdateRequestModel } from '../../models/product/ProductAddOrUpdateRequestModel';
import ProductFilterRequestModel, { initialProductFilterRequestModel } from '../../models/product/ProductFilterRequestModel';
import ProductListRequestModel from '../../models/product/ProductListRequestModel';
import UserListRequestModel from '../../models/user/UserListRequestModel';

import AuthenticationService from '../../services/authentication/AuthenticationService';
import GroupService from '../../services/group/GroupService';
import ProductService from '../../services/product/ProductService';
import UnitOfMeasureService from '../../services/unitOfMeasure/UnitOfMeasureService';
import UserService from '../../services/user/UserService';
import VatRateService from '../../services/vatRate/VatRateService';

// ----------------------------------------------------------------Privates---
const columns: TableColumnModel[] = [
    { name: 'Azonosító', align: 'right', hidden: true, },
    { name: 'Vonalkód', align: 'left', hidden: false, },
    { name: 'Megnevezés', align: 'left', hidden: false, },
    { name: 'Csoport azonosító', align: 'right', hidden: true, },
    { name: 'Csoport megnevezés', align: 'left', hidden: false, },
    { name: 'ÁFAkulcs azonosító', align: 'right', hidden: true, },
    { name: 'ÁFAkulcs megnevezés', align: 'left', hidden: false, },
    { name: 'Mennyiségi egység azonosító', align: 'right', hidden: true, },
    { name: 'Mennyiségi egység rövid megnevezés', align: 'left', hidden: false, },
    { name: 'Vámtarifa szám', align: 'left', hidden: false, },
    { name: 'Beszerzési nettó ár', align: 'right', hidden: false, },
    { name: 'Beszerzési bruttó ár', align: 'right', hidden: false, },
    { name: 'Eladási nettó ár', align: 'right', hidden: false, },
    { name: 'Eladási bruttó ár', align: 'right', hidden: false, },
    { name: 'Nettó súly', align: 'right', hidden: false, },
    { name: 'Bruttó súly', align: 'right', hidden: false, },
    { name: 'Szélesség', align: 'right', hidden: false, },
    { name: 'Magasság', align: 'right', hidden: false, },
    { name: 'Hosszúság', align: 'right', hidden: false, },
    { name: 'Megjegyzés', align: 'left', hidden: false, },
    { name: 'Gyári garancia', align: 'right', hidden: false, },
    { name: 'Közvetített szolgáltatás', align: 'center', hidden: false, },
    { name: 'Létrehozva', align: 'center', hidden: false, },
    { name: 'Módosítva', align: 'center', hidden: false, },
    { name: 'Létrehozó azonosító', align: 'right', hidden: true, },
    { name: 'Létrehozó felhasználónév', align: 'left', hidden: false, },
    { name: 'Módosító azonosító', align: 'right', hidden: true, },
    { name: 'Módosító felhasználónév', align: 'left', hidden: false, },
];

const isMediatedServiceStates: NamedBooleanModel[] = [
    { name: 'Saját', value: false, },
    { name: 'Közvetített szolgáltatás', value: true, },
];

interface State {
    dialogState: DialogState;
    setterState: ProductAddOrUpdateRequestModel;
    filterState: ProductFilterRequestModel;
    products: ProductEntity[];
    refresh: boolean;
    users: UserEntity[];
    groups: GroupEntity[];
    vatRates: VatRateEntity[];
    unitOfMeasures: UnitOfMeasureEntity[];
    page: number;
    rowCount: number;
    rowsPerPage: number;
}

interface DialogState {
    isOpened: boolean;
    type: DialogTypeEnumerator;
    id: number;
}

const initialState: State = {
    dialogState: {
        isOpened: false,
        type: DialogTypeEnumerator.Add,
        id: -1,
    },
    setterState: initialProductAddOrUpdateRequestModel,
    filterState: initialProductFilterRequestModel,
    products: [],
    refresh: false,
    users: [],
    groups: [],
    vatRates: [],
    unitOfMeasures: [],
    page: 0,
    rowCount: 0,
    rowsPerPage: 25,
}

const ProductsPage = (): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);
    const [debouncedState]: [ProductFilterRequestModel, DebouncedState<(value: ProductFilterRequestModel) => void>] = useDebounce<ProductFilterRequestModel>(state.filterState, debounceDelay);

    useEffect((): void => {
        const getProducts = async (): Promise<void> => {
            const filterData: ProductFilterRequestModel = {
                id: debouncedState.id! < 1 ? undefined : debouncedState.id,
                createdAtStart: debouncedState.createdAtStart === null ? undefined : debouncedState.createdAtStart,
                createdAtEnd: debouncedState.createdAtEnd === null ? undefined : debouncedState.createdAtEnd,
                updatedAtStart: debouncedState.updatedAtStart === null ? undefined : debouncedState.updatedAtStart,
                updatedAtEnd: debouncedState.updatedAtEnd === null ? undefined : debouncedState.updatedAtEnd,
                createdBy: debouncedState.createdBy,
                updatedBy: debouncedState.updatedBy,
                barcode: debouncedState.barcode?.length === 0 ? undefined : debouncedState.barcode,
                name: debouncedState.name?.length === 0 ? undefined : debouncedState.name,
                groupId: debouncedState.groupId,
                vatRateId: debouncedState.vatRateId,
                unitOfMeasureId: debouncedState.unitOfMeasureId,
                serviceListNumber: debouncedState.serviceListNumber?.length === 0 ? undefined : debouncedState.serviceListNumber,
                netPurchasePrice: debouncedState.netPurchasePrice! < 1 ? undefined : debouncedState.netPurchasePrice,
                grossPurchasePrice: debouncedState.grossPurchasePrice! < 1 ? undefined : debouncedState.grossPurchasePrice,
                netSalesPrice: debouncedState.netSalesPrice! < 1 ? undefined : debouncedState.netSalesPrice,
                grossSalesPrice: debouncedState.grossSalesPrice! < 1 ? undefined : debouncedState.grossSalesPrice,
                netWeight: debouncedState.netWeight! < 0 ? undefined : debouncedState.netWeight,
                grossWeight: debouncedState.grossWeight! < 0 ? undefined : debouncedState.grossWeight,
                width: debouncedState.width! < 0 ? undefined : debouncedState.width,
                height: debouncedState.height! < 0 ? undefined : debouncedState.height,
                length: debouncedState.length! < 0 ? undefined : debouncedState.length,
                comment: debouncedState.comment?.length === 0 ? undefined : debouncedState.comment,
                factoryWarranty: debouncedState.factoryWarranty! < 0 ? undefined : debouncedState.factoryWarranty,
                isMediatedService: debouncedState.isMediatedService === null ? undefined : debouncedState.isMediatedService,
            }

            const paginationData: PaginationRequestModel = {
                currentPage: state.page + 1,
                dataPerPage: state.rowsPerPage,
            }

            const requestData: ProductListRequestModel = {
                filter: filterData,
                orderBy: 'name_asc',
                pagination: paginationData,
            }

            const response: ResponseModel<ProductEntity[]> = await ProductService.getProducts(requestData);

            if (response.status === 200) {
                const responseProduct: ProductEntity[] = response.data as ProductEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    products: responseProduct,
                    rowCount: responseProduct.length,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(`${field.field}: ${field.message}`);
                    });
                }
            }
        }

        getProducts();
    }, [
        debouncedState.barcode,
        debouncedState.comment,
        debouncedState.createdAtEnd,
        debouncedState.createdAtStart,
        debouncedState.createdBy,
        debouncedState.factoryWarranty,
        debouncedState.grossPurchasePrice,
        debouncedState.grossSalesPrice,
        debouncedState.grossWeight,
        debouncedState.groupId,
        debouncedState.height,
        debouncedState.id,
        debouncedState.isMediatedService,
        debouncedState.length,
        debouncedState.name,
        debouncedState.netPurchasePrice,
        debouncedState.netSalesPrice,
        debouncedState.netWeight,
        debouncedState.serviceListNumber,
        debouncedState.unitOfMeasureId,
        debouncedState.updatedAtEnd,
        debouncedState.updatedAtStart,
        debouncedState.updatedBy,
        debouncedState.vatRateId,
        debouncedState.width,
        state.page,
        state.refresh,
        state.rowsPerPage,
    ]);

    useEffect((): void => {
        const getGroups = async (): Promise<void> => {
            const response: ResponseModel<GroupEntity[]> = await GroupService.getGroups();

            if (response.status === 200) {
                const responseGroup: GroupEntity[] = response.data as GroupEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    groups: responseGroup,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(`${field.field}: ${field.message}`);
                    });
                }
            }
        }

        const getVatRates = async (): Promise<void> => {
            const response: ResponseModel<VatRateEntity[]> = await VatRateService.getVatRates();

            if (response.status === 200) {
                const responseVatRate: VatRateEntity[] = response.data as VatRateEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    vatRates: responseVatRate,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(`${field.field}: ${field.message}`);
                    });
                }
            }
        }

        const getUnitOfMeasures = async (): Promise<void> => {
            const response: ResponseModel<UnitOfMeasureEntity[]> = await UnitOfMeasureService.getUnitOfMeasures();

            if (response.status === 200) {
                const responseUnitOfMeasure: UnitOfMeasureEntity[] = response.data as UnitOfMeasureEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    unitOfMeasures: responseUnitOfMeasure,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(`${field.field}: ${field.message}`);
                    });
                }
            }
        }

        const getUsers = async (): Promise<void> => {
            const requestData: UserListRequestModel = {
                orderBy: 'username_asc',
            }

            const response: ResponseModel<UserEntity[]> = await UserService.getUsers(requestData);

            if (response.status === 200) {
                const responseUser: UserEntity[] = response.data as UserEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    users: responseUser,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(`${field.field}: ${field.message}`);
                    });
                }
            }
        }

        getGroups();
        getVatRates();
        getUnitOfMeasures();
        getUsers();
    }, []);

    const getGroupByIdFromState = useCallback((id?: number): GroupEntity | null => {
        return state.groups.find((value: GroupEntity): boolean => value.id === id) ?? null;
    }, [state.groups]);

    const getVatRateByIdFromState = useCallback((id?: number): VatRateEntity | null => {
        return state.vatRates.find((value: VatRateEntity): boolean => value.id === id) ?? null;
    }, [state.vatRates]);

    const getUnitOfMeasureByIdFromState = useCallback((id?: number): UnitOfMeasureEntity | null => {
        return state.unitOfMeasures.find((value: UnitOfMeasureEntity): boolean => value.id === id) ?? null;
    }, [state.unitOfMeasures]);

    const getUserByIdFromState = useCallback((id?: number | null): UserEntity | null => {
        return state.users.find((value: UserEntity): boolean => value.id === id) ?? null;
    }, [state.users]);

    const getNamedBooleanByIdFromState = useCallback((id?: boolean | null): NamedBooleanModel | null => {
        return isMediatedServiceStates.find((value: NamedBooleanModel): boolean => value.value === id) ?? null;
    }, []);

    const clearDialogState = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            dialogState: initialState.dialogState,
            setterState: initialState.setterState,
        }));
    }, []);

    const validateModifiedData = useCallback((): boolean => {
        if (
            (!state.setterState.barcode)
            || ((state.setterState.barcode)
                && (state.setterState.barcode.length === 0))
        ) {
            toast.error('"Vonalkód" mező kitöltése kötelező.');
            return false;
        }

        if (
            (!state.setterState.name)
            || ((state.setterState.name)
                && (state.setterState.name.length === 0))
        ) {
            toast.error('"Megnevezés" mező kitöltése kötelező.');
            return false;
        }

        if (!state.setterState.groupId) {
            toast.error('"Csoport" érték kiválasztása kötelező.');
            return false;
        }

        if (!state.setterState.vatRateId) {
            toast.error('"ÁFAkulcs" érték kiválasztása kötelező.');
            return false;
        }

        if (!state.setterState.unitOfMeasureId) {
            toast.error('"Mennyiségi egység" érték kiválasztása kötelező.');
            return false;
        }

        if ((!state.setterState.grossSalesPrice)
            || (state.setterState.grossSalesPrice <= 0)) {
            toast.error('"Eladási bruttó ár" nem lehet kisebb vagy egyenlő nullánál.');
            return false;
        }

        return true;
    }, [
        state.setterState.barcode,
        state.setterState.grossSalesPrice,
        state.setterState.groupId,
        state.setterState.name,
        state.setterState.unitOfMeasureId,
        state.setterState.vatRateId,
    ]);

    const addData = useCallback(async (): Promise<boolean> => {
        if (!validateModifiedData()) {
            return false;
        }

        const requestData: ProductAddOrUpdateRequestModel = {
            barcode: state.setterState.barcode,
            name: state.setterState.name,
            groupId: state.setterState.groupId,
            vatRateId: state.setterState.vatRateId,
            unitOfMeasureId: state.setterState.unitOfMeasureId,
            serviceListNumber: state.setterState.serviceListNumber,
            netPurchasePrice: state.setterState.netPurchasePrice,
            grossPurchasePrice: state.setterState.grossPurchasePrice,
            netSalesPrice: state.setterState.netSalesPrice,
            grossSalesPrice: state.setterState.grossSalesPrice,
            netWeight: state.setterState.netWeight,
            grossWeight: state.setterState.grossWeight,
            width: state.setterState.width,
            height: state.setterState.height,
            length: state.setterState.length,
            comment: state.setterState.comment,
            factoryWarranty: state.setterState.factoryWarranty,
            isMediatedService: state.setterState.isMediatedService,
        }

        const response: ResponseModel<ProductEntity> = await ProductService.addProduct(requestData);

        if (response.status !== 200) {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(`${field.field}: ${field.message}`);
                });
            }

            return false;
        }
        setState((prevState: State): State => ({
            ...prevState,
            refresh: !prevState.refresh,
        }));

        return true;
    }, [
        state.setterState.barcode,
        state.setterState.comment,
        state.setterState.factoryWarranty,
        state.setterState.grossPurchasePrice,
        state.setterState.grossSalesPrice,
        state.setterState.grossWeight,
        state.setterState.groupId,
        state.setterState.height,
        state.setterState.isMediatedService,
        state.setterState.length,
        state.setterState.name,
        state.setterState.netPurchasePrice,
        state.setterState.netSalesPrice,
        state.setterState.netWeight,
        state.setterState.serviceListNumber,
        state.setterState.unitOfMeasureId,
        state.setterState.vatRateId,
        state.setterState.width,
        validateModifiedData,
    ]);

    const updateData = useCallback(async (): Promise<boolean> => {
        if (!validateModifiedData()) {
            return false;
        }

        const requestData: ProductAddOrUpdateRequestModel = {
            barcode: state.setterState.barcode,
            name: state.setterState.name,
            groupId: state.setterState.groupId,
            vatRateId: state.setterState.vatRateId,
            unitOfMeasureId: state.setterState.unitOfMeasureId,
            serviceListNumber: state.setterState.serviceListNumber,
            netPurchasePrice: state.setterState.netPurchasePrice,
            grossPurchasePrice: state.setterState.grossPurchasePrice,
            netSalesPrice: state.setterState.netSalesPrice,
            grossSalesPrice: state.setterState.grossSalesPrice,
            netWeight: state.setterState.netWeight,
            grossWeight: state.setterState.grossWeight,
            width: state.setterState.width,
            height: state.setterState.height,
            length: state.setterState.length,
            comment: state.setterState.comment,
            factoryWarranty: state.setterState.factoryWarranty,
            isMediatedService: state.setterState.isMediatedService,
        }

        const response: ResponseModel<ProductEntity> = await ProductService.updateProduct(state.dialogState.id, requestData);

        if (response.status !== 200) {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(`${field.field}: ${field.message}`);
                });
            }

            return false;
        }

        setState((prevState: State): State => ({
            ...prevState,
            refresh: !prevState.refresh,
        }));

        return true;
    }, [
        state.dialogState.id,
        state.setterState.barcode,
        state.setterState.comment,
        state.setterState.factoryWarranty,
        state.setterState.grossPurchasePrice,
        state.setterState.grossSalesPrice,
        state.setterState.grossWeight,
        state.setterState.groupId,
        state.setterState.height,
        state.setterState.isMediatedService,
        state.setterState.length,
        state.setterState.name,
        state.setterState.netPurchasePrice,
        state.setterState.netSalesPrice,
        state.setterState.netWeight,
        state.setterState.serviceListNumber,
        state.setterState.unitOfMeasureId,
        state.setterState.vatRateId,
        state.setterState.width,
        validateModifiedData,
    ]);

    const removeData = useCallback(async (): Promise<void> => {
        const response: ResponseModel<string> = await ProductService.removeProduct(state.dialogState.id);

        if (response.status === 200) {
            setState((prevState: State): State => ({
                ...prevState,
                refresh: !prevState.refresh,
            }));
        } else {
            toast.error(response.error?.message);
            if (response.error?.fields) {
                response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                    toast.error(`${field.field}: ${field.message}`);
                });
            }
        }
    }, [state.dialogState.id]);

    // --------------------------------------------------------------Events---
    const handleClickAddButton = useCallback((): void => {
        clearDialogState();

        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Add,
            },
        }));
    }, [clearDialogState]);

    const handleClickUpdateButton = useCallback((id: number, barcode: string, name: string, groupId: number, vatRateId: number, unitOfMeasureId: number, serviceListNumber: string, netPurchasePrice: number, grossPurchasePrice: number, netSalesPrice: number, grossSalesPrice: number, netWeight: number, grossWeight: number, width: number, height: number, length: number, comment: string, factoryWarranty: number, isMediatedService: boolean): void => {
        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Update,
                id: id,
            },
            setterState: {
                barcode: barcode,
                name: name,
                groupId: getGroupByIdFromState(groupId)?.id,
                vatRateId: getVatRateByIdFromState(vatRateId)?.id,
                unitOfMeasureId: getUnitOfMeasureByIdFromState(unitOfMeasureId)?.id,
                serviceListNumber: serviceListNumber,
                netPurchasePrice: netPurchasePrice,
                grossPurchasePrice: grossPurchasePrice,
                netSalesPrice: netSalesPrice,
                grossSalesPrice: grossSalesPrice,
                netWeight: netWeight,
                grossWeight: grossWeight,
                width: width,
                height: height,
                length: length,
                comment: comment,
                factoryWarranty: factoryWarranty,
                isMediatedService: isMediatedService,
            },
        }));
    }, [
        getGroupByIdFromState,
        getUnitOfMeasureByIdFromState,
        getVatRateByIdFromState,
    ]);

    const handleClickRemoveButton = useCallback((id: number, barcode: string): void => {
        clearDialogState();

        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: true,
                type: DialogTypeEnumerator.Remove,
                id: id,
            },
            setterState: {
                ...prevState.setterState,
                barcode: barcode,
            },
        }));
    }, [clearDialogState]);

    const handleClickCloseDialog = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            dialogState: {
                ...prevState.dialogState,
                isOpened: false,
            },
        }));
    }, []);

    const handleClickSaveButtonFromDialog = useCallback(async (): Promise<void> => {
        let isSaved: boolean = true;

        switch (state.dialogState.type) {
            case DialogTypeEnumerator.Add: {
                isSaved = await addData();
                break;
            }
            case DialogTypeEnumerator.Update: {
                isSaved = await updateData();
                break;
            }
            case DialogTypeEnumerator.Remove: {
                await removeData();
                break;
            }
            default: {
                break;
            }
        }

        if (!isSaved) {
            return;
        }

        clearDialogState();
        handleClickCloseDialog();

        toast.success(`Sikeres ${state.dialogState.type.toLocaleLowerCase()}.`);
    }, [
        addData,
        clearDialogState,
        state.dialogState.type,
        handleClickCloseDialog,
        removeData,
        updateData,
    ]);

    const handleChangeBarcodeDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                barcode: event.target.value,
            },
        }));
    }, []);

    const handleChangeNameDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                name: event.target.value,
            },
        }));
    }, []);

    const handleChangeGroupDialog = useCallback((event: SyntheticEvent, newValue: GroupEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                groupId: newValue?.id,
            },
        }));
    }, []);

    const handleChangeVatRateDialog = useCallback((event: SyntheticEvent, newValue: VatRateEntity | null): void => {
        const netSalesPrice: number = state.setterState.netSalesPrice ?? 0;
        let grossSalesPrice: number = state.setterState.grossSalesPrice ?? 0;
        const netPurchasePrice: number = state.setterState.netPurchasePrice ?? 0;
        let grossPurchasePrice: number = state.setterState.grossPurchasePrice ?? 0;

        if (newValue) {
            grossSalesPrice = grossFromNet(netSalesPrice, newValue.value!);
            grossPurchasePrice = grossFromNet(netPurchasePrice, newValue.value!);
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                vatRateId: newValue?.id,
                grossPurchasePrice: grossPurchasePrice,
                grossSalesPrice: grossSalesPrice,
            },
        }));
    }, [
        state.setterState.grossPurchasePrice,
        state.setterState.grossSalesPrice,
        state.setterState.netPurchasePrice,
        state.setterState.netSalesPrice,
    ]);

    const handleChangeUnitOfMeasureDialog = useCallback((event: SyntheticEvent, newValue: UnitOfMeasureEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                unitOfMeasureId: newValue?.id,
            },
        }));
    }, []);

    const handleChangeServiceListNumberDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                serviceListNumber: event.target.value,
            },
        }));
    }, []);

    const handleChangeNetPurchasePriceDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        const netPurchasePrice: number = Number(event.target.value);
        let grossPurchasePrice: number = state.setterState.grossPurchasePrice ?? 0;

        if (state.setterState.vatRateId) {
            grossPurchasePrice = grossFromNet(netPurchasePrice, getVatRateByIdFromState(state.setterState.vatRateId)?.value);
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                netPurchasePrice: roundTo(netPurchasePrice),
                grossPurchasePrice: roundTo(grossPurchasePrice),
            },
        }));
    }, [
        getVatRateByIdFromState,
        state.setterState.grossPurchasePrice,
        state.setterState.vatRateId,
    ]);

    const handleChangeGrossPurchasePriceDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        const grossPurchasePrice: number = Number(event.target.value);
        let netPurchasePrice: number = state.setterState.netPurchasePrice ?? 0;

        if (state.setterState.vatRateId) {
            netPurchasePrice = netFromGross(grossPurchasePrice, getVatRateByIdFromState(state.setterState.vatRateId)?.value!);
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                netPurchasePrice: roundTo(netPurchasePrice),
                grossPurchasePrice: roundTo(grossPurchasePrice),
            },
        }));
    }, [
        getVatRateByIdFromState,
        state.setterState.netPurchasePrice,
        state.setterState.vatRateId,
    ]);

    const handleChangeNetSalesPriceDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        const netSalesPrice: number = Number(event.target.value);
        let grossSalesPrice: number = state.setterState.grossSalesPrice ?? 0;

        if (state.setterState.vatRateId) {
            grossSalesPrice = grossFromNet(netSalesPrice, getVatRateByIdFromState(state.setterState.vatRateId)?.value!);
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                netSalesPrice: roundTo(netSalesPrice),
                grossSalesPrice: roundTo(grossSalesPrice),
            },
        }));
    }, [
        getVatRateByIdFromState,
        state.setterState.grossSalesPrice,
        state.setterState.vatRateId,
    ]);

    const handleChangeGrossSalesPriceDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        const grossSalesPrice: number = Number(event.target.value);
        let netSalesPrice: number = state.setterState.netSalesPrice ?? 0;

        if (state.setterState.vatRateId) {
            netSalesPrice = netFromGross(grossSalesPrice, getVatRateByIdFromState(state.setterState.vatRateId)?.value!);
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                netSalesPrice: roundTo(netSalesPrice),
                grossSalesPrice: roundTo(grossSalesPrice),
            },
        }));
    }, [
        getVatRateByIdFromState,
        state.setterState.netSalesPrice,
        state.setterState.vatRateId,
    ]);

    const handleChangeNetWeightDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                netWeight: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeGrossWeightDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                grossWeight: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeWidthDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                width: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeHeightDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                height: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeLengthDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                length: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeCommentDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                comment: event.target.value,
            },
        }));
    }, []);

    const handleChangeFactoryWarrantyDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                factoryWarranty: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeIsMediatedServiceDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            setterState: {
                ...prevState.setterState,
                isMediatedService: event.target.checked,
            },
        }));
    }, []);

    const handleChangeCreatedAtStartFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                createdAtStart: event,
            },
        }));
    }, []);

    const handleChangeCreatedAtEndFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                createdAtEnd: event,
            },
        }));
    }, []);

    const handleChangeUpdatedAtStartFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                updatedAtStart: event,
            },
        }));
    }, []);

    const handleChangeUpdatedAtEndFilter = useCallback((event: Date | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                updatedAtEnd: event,
            },
        }));
    }, []);

    const handleChangeCreatedByFilter = useCallback((event: SyntheticEvent, newValue: UserEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                createdBy: newValue?.id,
            },
        }));
    }, []);

    const handleChangeUpdatedByFilter = useCallback((event: SyntheticEvent, newValue: UserEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                updatedBy: newValue?.id,
            },
        }));
    }, []);

    const handleChangeIdFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                id: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeBarcodeFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                barcode: event.target.value,
            },
        }));
    }, []);

    const handleChangeNameFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                name: event.target.value,
            },
        }));
    }, []);

    const handleChangeGroupIdFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                groupId: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeGroupFilter = useCallback((event: SyntheticEvent, newValue: GroupEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                groupId: newValue?.id,
            },
        }));
    }, []);

    const handleChangeVatRateIdFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                vatRateId: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeVatRateFilter = useCallback((event: SyntheticEvent, newValue: VatRateEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                vatRateId: newValue?.id,
            },
        }));
    }, []);

    const handleChangeUnitOfMeasureIdFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                unitOfMeasureId: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeUnitOfMeasureFilter = useCallback((event: SyntheticEvent, newValue: UnitOfMeasureEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                unitOfMeasureId: newValue?.id,
            },
        }));
    }, []);

    const handleChangeServiceListNumberFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                serviceListNumber: event.target.value,
            },
        }));
    }, []);

    const handleChangeNetPurchasePriceFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                netPurchasePrice: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeGrossPurchasePriceFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                grossPurchasePrice: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeNetSalesPriceFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                netSalesPrice: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeGrossSalesPriceFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                grossSalesPrice: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeNetWeightFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                netWeight: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeGrossWeightFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                grossWeight: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeWidthFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                width: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeHeightFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                height: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeLengthFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                length: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeCommentFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                comment: event.target.value,
            },
        }));
    }, []);

    const handleChangeFactoryWarrantyFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                factoryWarranty: Number(event.target.value),
            },
        }));
    }, []);

    const handleChangeIsMediatedServiceFilter = useCallback((event: SyntheticEvent, newValue: NamedBooleanModel | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                isMediatedService: newValue?.value,
            },
        }));
    }, []);

    const handlePageChange = useCallback((event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setState((prevState: State): State => ({
            ...prevState,
            page: newPage,
        }));
    }, []);

    const handleRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            rowsPerPage: Number(event.target.value),
            page: 0,
        }));
    }, []);

    // --------------------------------------------------------------Return---
    return (
        <>
            <Paper sx={PaperSxProps(theme).filter}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>
                            Szűrés
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LocalizationProvider adapterLocale={'hu'} dateAdapter={AdapterMoment}>
                            <Grid container spacing={2}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label={'Létrehozva kezdet'} onChange={handleChangeCreatedAtStartFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.createdAtStart} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label={'Létrehozva vég'} onChange={handleChangeCreatedAtEndFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.createdAtEnd} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label={'Módosítva kezdet'} onChange={handleChangeUpdatedAtStartFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.updatedAtStart} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} format={dateTimeFormat} label={'Módosítva vég'} onChange={handleChangeUpdatedAtEndFilter} slotProps={{ textField: { fullWidth: true, } }} value={state.filterState.updatedAtEnd} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: UserEntity) => option.username ?? ''}
                                        onChange={handleChangeCreatedByFilter}
                                        options={state.users}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Létrehozó felhasználó'} />}
                                        value={getUserByIdFromState(state.filterState.createdBy)}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: UserEntity) => option.username ?? ''}
                                        onChange={handleChangeUpdatedByFilter}
                                        options={state.users}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Módosító felhasználó'} />}
                                        value={getUserByIdFromState(state.filterState.updatedBy)}
                                    />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <TextField fullWidth label={'Azonosító'} onChange={handleChangeIdFilter} type={'number'} value={state.filterState.id} />
                                    </Grid>
                                )}
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Vonalkód'} onChange={handleChangeBarcodeFilter} type={'text'} value={state.filterState.barcode} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Megnevezés'} onChange={handleChangeNameFilter} type={'text'} value={state.filterState.name} />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <TextField fullWidth label={'Csoport azonosító'} onChange={handleChangeGroupIdFilter} type={'number'} value={state.filterState.groupId} />
                                    </Grid>
                                )}
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: GroupEntity) => option.name ?? ''}
                                        onChange={handleChangeGroupFilter}
                                        options={state.groups}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Csoport'} />}
                                        value={getGroupByIdFromState(state.filterState.groupId)}
                                    />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <TextField fullWidth label={'ÁFAkulcs azonosító'} onChange={handleChangeVatRateIdFilter} type={'number'} value={state.filterState.vatRateId} />
                                    </Grid>
                                )}
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: VatRateEntity) => option.name ?? ''}
                                        onChange={handleChangeVatRateFilter}
                                        options={state.vatRates}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'ÁFAkulcs'} />}
                                        value={getVatRateByIdFromState(state.filterState.vatRateId)}
                                    />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <TextField fullWidth label={'Mennyiségi egység azonosító'} onChange={handleChangeUnitOfMeasureIdFilter} type={'number'} value={state.filterState.unitOfMeasureId} />
                                    </Grid>
                                )}
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: UnitOfMeasureEntity) => option.shortName ?? ''}
                                        onChange={handleChangeUnitOfMeasureFilter}
                                        options={state.unitOfMeasures}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Mennyiségi egység'} />}
                                        value={getUnitOfMeasureByIdFromState(state.filterState.unitOfMeasureId)}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Vámtarifa szám'} onChange={handleChangeServiceListNumberFilter} type={'text'} value={state.filterState.serviceListNumber} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Beszerzési nettó ár'} onChange={handleChangeNetPurchasePriceFilter} type={'number'} value={state.filterState.netPurchasePrice} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Beszerzési bruttó ár'} onChange={handleChangeGrossPurchasePriceFilter} type={'number'} value={state.filterState.grossPurchasePrice} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Eladási nettó ár'} onChange={handleChangeNetSalesPriceFilter} type={'number'} value={state.filterState.netSalesPrice} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Eladási bruttó ár'} onChange={handleChangeGrossSalesPriceFilter} type={'number'} value={state.filterState.grossSalesPrice} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Nettó súly'} onChange={handleChangeNetWeightFilter} type={'number'} value={state.filterState.netWeight} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Bruttó súly'} onChange={handleChangeGrossWeightFilter} type={'number'} value={state.filterState.grossWeight} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Szélesség'} onChange={handleChangeWidthFilter} type={'number'} value={state.filterState.width} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Magasság'} onChange={handleChangeHeightFilter} type={'number'} value={state.filterState.height} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Hosszúság'} onChange={handleChangeLengthFilter} type={'number'} value={state.filterState.length} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Megjegyzés'} multiline onChange={handleChangeCommentFilter} type={'text'} value={state.filterState.comment} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <TextField fullWidth label={'Gyári garancia'} onChange={handleChangeFactoryWarrantyFilter} type={'number'} value={state.filterState.factoryWarranty} />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <Autocomplete
                                        autoComplete
                                        filterSelectedOptions
                                        fullWidth
                                        getOptionLabel={(option: NamedBooleanModel) => option.name ?? ''}
                                        onChange={handleChangeIsMediatedServiceFilter}
                                        options={isMediatedServiceStates}
                                        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Közvetített szolgáltatás'} />}
                                        value={getNamedBooleanByIdFromState(state.filterState.isMediatedService)}
                                    />
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </AccordionDetails>
                </Accordion>
            </Paper>

            <Paper>
                <TableContainer>
                    <Table size={'small'} stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns
                                    .filter((column: TableColumnModel): boolean => (!column.hidden) || (AuthenticationService.adminState()))
                                    .map((column: TableColumnModel, columnKey: Key): JSX.Element => (
                                        <TableCell align={column.align} key={columnKey}>
                                            <TableSortLabel>
                                                {column.name}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))
                                }
                                <TableCell align={'center'} />
                                <TableCell align={'center'} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.products.map((product: ProductEntity, productKey: Key): JSX.Element => {
                                return (
                                    <TableRow key={productKey} sx={TableRowSxProps(theme).bodyRow}>
                                        {((!columns[0].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[0].align}>{product.id!.toLocaleString()}</TableCell>)}
                                        {((!columns[1].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[1].align}>{product.barcode!}</TableCell>)}
                                        {((!columns[2].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[2].align}>{product.name!}</TableCell>)}
                                        {((!columns[3].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[3].align}>{product.groupId!.toLocaleString()}</TableCell>)}
                                        {((!columns[4].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[4].align}>{product.group?.name!}</TableCell>)}
                                        {((!columns[5].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[5].align}>{product.vatRateId!.toLocaleString()}</TableCell>)}
                                        {((!columns[6].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[6].align}>{product.vatRate?.name!}</TableCell>)}
                                        {((!columns[7].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[7].align}>{product.unitOfMeasureId!.toLocaleString()}</TableCell>)}
                                        {((!columns[8].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[8].align}>{product.unitOfMeasure?.shortName!}</TableCell>)}
                                        {((!columns[9].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[9].align}>{product.serviceListNumber!}</TableCell>)}
                                        {((!columns[10].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[10].align}>{product.netPurchasePrice!.toLocaleString()}</TableCell>)}
                                        {((!columns[11].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[11].align}>{product.grossPurchasePrice!.toLocaleString()}</TableCell>)}
                                        {((!columns[12].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[12].align}>{product.netSalesPrice!.toLocaleString()}</TableCell>)}
                                        {((!columns[13].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[13].align}>{product.grossSalesPrice!.toLocaleString()}</TableCell>)}
                                        {((!columns[14].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[14].align}>{product.netWeight!.toLocaleString()}</TableCell>)}
                                        {((!columns[15].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[15].align}>{product.grossWeight!.toLocaleString()}</TableCell>)}
                                        {((!columns[16].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[16].align}>{product.width!.toLocaleString()}</TableCell>)}
                                        {((!columns[17].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[17].align}>{product.height!.toLocaleString()}</TableCell>)}
                                        {((!columns[18].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[18].align}>{product.length!.toLocaleString()}</TableCell>)}
                                        {((!columns[19].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[19].align}>{product.comment!}</TableCell>)}
                                        {((!columns[20].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[20].align}>{product.factoryWarranty!.toLocaleString()}</TableCell>)}
                                        {((!columns[21].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[21].align}>{product.isMediatedService! ? <Chip color={'success'} label={'igen'} /> : <Chip color={'error'} label={'nem'} />}</TableCell>)}
                                        {((!columns[22].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[22].align}>{moment(product.createdAt!).format(dateTimeFormat)}</TableCell>)}
                                        {((!columns[23].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[23].align}>{moment(product.updatedAt!).format(dateTimeFormat)}</TableCell>)}
                                        {((!columns[24].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[24].align}>{product.createdBy!}</TableCell>)}
                                        {((!columns[25].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[25].align}>{product.createdByUser!.username!}</TableCell>)}
                                        {((!columns[26].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[26].align}>{product.updatedBy!}</TableCell>)}
                                        {((!columns[27].hidden) || (AuthenticationService.adminState())) && (<TableCell align={columns[27].align}>{product.updatedByUser!.username!}</TableCell>)}
                                        <TableCell align={'center'}><Fab color={'warning'} onClick={(): void => handleClickUpdateButton(product.id!, product.barcode!, product.name!, product.groupId!, product.vatRateId!, product.unitOfMeasureId!, product.serviceListNumber!, product.netPurchasePrice!, product.grossPurchasePrice!, product.netSalesPrice!, product.grossSalesPrice!, product.netWeight!, product.grossWeight!, product.width!, product.height!, product.length!, product.comment!, product.factoryWarranty!, product.isMediatedService!)} size={'small'}><Settings /></Fab></TableCell>
                                        <TableCell align={'center'}><Fab color={'error'} onClick={(): void => handleClickRemoveButton(product.id!, product.barcode!)} size={'small'}><Delete /></Fab></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination component={'div'} count={state.rowCount} onPageChange={handlePageChange} onRowsPerPageChange={handleRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} />
            </Paper>

            <Fab aria-label={'add'} color={'success'} onClick={handleClickAddButton} style={fabStyles.add}><Add /></Fab>

            <DialogComponent
                dataToBeRemoved={state.setterState.barcode ?? ''}
                type={state.dialogState.type}
                contentFields={
                    <>
                        <TextField autoFocus fullWidth inputProps={{ maxLength: 20, }} label={'Vonalkód'} onChange={handleChangeBarcodeDialog} type={'text'} value={state.setterState.barcode} />
                        <TextField fullWidth inputProps={{ maxLength: 250, }} label={'Megnevezés'} onChange={handleChangeNameDialog} type={'text'} value={state.setterState.name} />
                        <Autocomplete
                            autoComplete
                            filterSelectedOptions
                            fullWidth
                            getOptionLabel={(option: GroupEntity) => `${option.name} (${option.id?.toString()})` ?? ''}
                            onChange={handleChangeGroupDialog}
                            options={state.groups}
                            renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Csoport'} />}
                            value={getGroupByIdFromState(state.setterState.groupId)}
                        />
                        <Autocomplete
                            autoComplete
                            filterSelectedOptions
                            fullWidth
                            getOptionLabel={(option: VatRateEntity) => `${option.name} (${option.id?.toString()})` ?? ''}
                            onChange={handleChangeVatRateDialog}
                            options={state.vatRates}
                            renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'ÁFAkulcs'} />}
                            value={getVatRateByIdFromState(state.setterState.vatRateId)}
                        />
                        <Autocomplete
                            autoComplete
                            filterSelectedOptions
                            fullWidth
                            getOptionLabel={(option: UnitOfMeasureEntity) => `${option.shortName} (${option.id?.toString()})` ?? ''}
                            onChange={handleChangeUnitOfMeasureDialog}
                            options={state.unitOfMeasures}
                            renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Mennyiségi egység'} />}
                            value={getUnitOfMeasureByIdFromState(state.setterState.unitOfMeasureId)}
                        />
                        <TextField fullWidth inputProps={{ maxLength: 10, }} label={'SZJ kód'} onChange={handleChangeServiceListNumberDialog} type={'text'} value={state.setterState.serviceListNumber} />
                        <TextField fullWidth label={'Beszerzési nettó ár'} onChange={handleChangeNetPurchasePriceDialog} type={'number'} value={state.setterState.netPurchasePrice} />
                        <TextField fullWidth label={'Beszerzési bruttó ár'} onChange={handleChangeGrossPurchasePriceDialog} type={'number'} value={state.setterState.grossPurchasePrice} />
                        <TextField fullWidth label={'Eladási nettó ár'} onChange={handleChangeNetSalesPriceDialog} type={'number'} value={state.setterState.netSalesPrice} />
                        <TextField fullWidth label={'Eladási bruttó ár'} onChange={handleChangeGrossSalesPriceDialog} type={'number'} value={state.setterState.grossSalesPrice} />
                        <TextField fullWidth label={'Nettó súly'} onChange={handleChangeNetWeightDialog} type={'number'} value={state.setterState.netWeight} />
                        <TextField fullWidth label={'Bruttó súly'} onChange={handleChangeGrossWeightDialog} type={'number'} value={state.setterState.grossWeight} />
                        <TextField fullWidth label={'Szélesség'} onChange={handleChangeWidthDialog} type={'number'} value={state.setterState.width} />
                        <TextField fullWidth label={'Magasság'} onChange={handleChangeHeightDialog} type={'number'} value={state.setterState.height} />
                        <TextField fullWidth label={'Hosszúság'} onChange={handleChangeLengthDialog} type={'number'} value={state.setterState.length} />
                        <TextField fullWidth label={'Megjegyzés'} multiline onChange={handleChangeCommentDialog} type={'text'} value={state.setterState.comment} />
                        <TextField fullWidth label={'Gyári garancia'} onChange={handleChangeFactoryWarrantyDialog} type={'number'} value={state.setterState.factoryWarranty} />
                        <FormControlLabel control={<Switch checked={state.setterState.isMediatedService} onChange={handleChangeIsMediatedServiceDialog} />} label={state.setterState.isMediatedService ? 'Közvetített szolgáltatás' : 'Saját'} />
                    </>
                }
                isOpened={state.dialogState.isOpened}
                callbackClickClose={handleClickCloseDialog}
                callbackClickSave={handleClickSaveButtonFromDialog}
            />
        </>
    );
}

// -----------------------------------------------------------------Exports---
export default ProductsPage;
