// -----------------------------------------------------------------Imports---
import { ChangeEvent } from 'react';

import {
    Grid,
    TextField,
} from '@mui/material';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackChangeBarcodeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
    callbackChangeNameFilter: (event: ChangeEvent<HTMLInputElement>) => void;
    filteredBarcode: string;
    filteredName: string;
}

/**
 * Termék szűrő
 * @param callbackChangeBarcodeFilter Vonalkód esemény
 * @param callbackChangeNameFilter Megnevezés esemény
 * @param filteredBarcode Vonalkód szűrő
 * @param filteredName Megnevezés szűrő
 */
const SubProductFilteringComponent = ({
    callbackChangeBarcodeFilter,
    callbackChangeNameFilter,
    filteredBarcode,
    filteredName,
}: Props): JSX.Element => {
    // --------------------------------------------------------------Styles---
    const gridStyles: CSSPropertiesModel = {
        left: {
            paddingRight: 4,
        },
        right: {
            paddingLeft: 4,
        }
    }

    // --------------------------------------------------------------Return---
    return (
        <Grid item container>
            <Grid
                item
                style={gridStyles.left}
                xs={6}
            >
                <TextField
                    fullWidth
                    label={'Vonalkód'}
                    onChange={callbackChangeBarcodeFilter}
                    value={filteredBarcode}
                />
            </Grid>
            <Grid
                item
                style={gridStyles.right}
                xs={6}
            >
                <TextField
                    fullWidth
                    label={'Megnevezés'}
                    onChange={callbackChangeNameFilter}
                    value={filteredName}
                />
            </Grid>
        </Grid>
    );
}

// -----------------------------------------------------------------Exports---
export default SubProductFilteringComponent;
