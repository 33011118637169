// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import DeliveryMethodEntity from '../../entities/DeliveryMethodEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import DeliveryMethodAddOrUpdateRequestModel from '../../models/deliveryMethod/DeliveryMethodAddOrUpdateRequestModel';
import DeliveryMethodListRequestModel from '../../models/deliveryMethod/DeliveryMethodListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getDeliveryMethods(data?: DeliveryMethodListRequestModel): Promise<ResponseModel<DeliveryMethodEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.deliveryMethod.getDeliveryMethods,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async addDeliveryMethod(data: DeliveryMethodAddOrUpdateRequestModel): Promise<ResponseModel<DeliveryMethodEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.deliveryMethod.addDeliveryMethod,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async updateDeliveryMethod(id: number, data?: DeliveryMethodAddOrUpdateRequestModel): Promise<ResponseModel<DeliveryMethodEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.deliveryMethod.updateDeliveryMethod.replace('{id}', id.toString()),
            'PUT',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async removeDeliveryMethod(id: number): Promise<ResponseModel<string>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.deliveryMethod.removeDeliveryMethod.replace('{id}', id.toString()),
            'DELETE',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        );
    }
}

const DeliveryMethodService = new LocalService();

// -----------------------------------------------------------------Exports---
export default DeliveryMethodService;
