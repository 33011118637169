// -----------------------------------------------------------------Imports---
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Stack,
    Typography,
} from '@mui/material';

// ----------------------------------------------------------------Privates---
const HomePage = (): JSX.Element => {
    // --------------------------------------------------------------Return---
    return (
        <Box>
            <Card>
                <CardMedia alt={'homeLogo'} component={'img'} image={''} />
                <Divider variant={'middle'} />
                <CardHeader title={'Fő oldal'} />
                <CardContent>
                    <Stack>
                        <Typography>
                            Fő oldal
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
}

// -----------------------------------------------------------------Exports---
export default HomePage;
