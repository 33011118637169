// -----------------------------------------------------------------Imports---
import {
    createTheme,
    Theme,
} from '@mui/material';

import ThemeModeEnumerator from './enumerators/ThemeModeEnumerator';

// -----------------------------------------------------------------Exports---
export const globalTheme = (mode: ThemeModeEnumerator): Theme => {
    return createTheme({
        palette: {
            mode: mode,
        },
    });
}
