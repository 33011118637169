// -----------------------------------------------------------------Imports---
import { DialogTitle } from '@mui/material';

import CSSPropertiesModel from '../../../models/CSSPropertiesModel';

// ----------------------------------------------------------------Privates---
interface Props {
    title: string;
}

/**
 * Általános felugró 'Dialog' ablakhoz címsor
 * @param title Cím
 */
const SubDialogTitleComponent = ({ title, }: Props): JSX.Element => {
    // --------------------------------------------------------------Styles---
    const dialogTitleStyles: CSSPropertiesModel = {
        main: {
            textAlign: 'center',
            userSelect: 'none',
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <DialogTitle style={dialogTitleStyles.main}>
            {title}
        </DialogTitle>
    );
}

// -----------------------------------------------------------------Exports---
export default SubDialogTitleComponent;
