// -----------------------------------------------------------------Imports---
import moment from 'moment';

import {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Location,
    NavigateFunction,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    toast,
    ToastContainer,
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import {
    Box,
    CssBaseline,
    Theme,
    ThemeProvider,
    useTheme,
} from '@mui/material';

import { globalTheme } from './App.style';

import { getAppRoutes } from './Global';

import DrawerComponent from './components/drawer/DrawerComponent';
import HeaderComponent from './components/header/HeaderComponent';

import ThemeModeEnumerator from './enumerators/ThemeModeEnumerator';

import CSSPropertiesModel from './models/CSSPropertiesModel';

import CountriesPage from './pages/countries/CountriesPage';
import GroupsPage from './pages/groups/GroupsPage';
import HomePage from './pages/home/HomePage';
import LoginPage from './pages/login/LoginPage';
import NotFoundPage from './pages/notFound/NotFoundPage';
import PartnersPage from './pages/partners/PartnersPage';
import ProductsPage from './pages/products/ProductsPage';
import SaleInvoicePage from './pages/sale/invoice/SaleInvoicePage';
import ServiceListsPage from './pages/serviceLists/ServiceListsPage';
import UnitOfMeasuresPage from './pages/unitOfMeasures/UnitOfMeasuresPage';
import VatRatesPage from './pages/vatRates/VatRatesPage';

import AuthenticationService from './services/authentication/AuthenticationService';
import SxPropsModel from './models/SxPropsModel';

// ----------------------------------------------------------------Privates---
const exceptPaths: string[] = [getAppRoutes.login];
const drawerWidth: number = 275;

/**
 * Alkalmazás központ
 */
const App = (): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const [isLoggedIn, setIsLoggedIn]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
    const [isDrawerOpened, setIsDrawerOpened]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
    const [isDarkMode, setIsDarkMode]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);

    const { pathname }: Location = useLocation();
    const navigate: NavigateFunction = useNavigate();
    const theme: Theme = useTheme<Theme>();

    const currentTheme: Theme = useMemo((): Theme => {
        return globalTheme(isDarkMode ? ThemeModeEnumerator.Dark : ThemeModeEnumerator.Light);
    }, [isDarkMode]);

    useEffect((): () => void => {
        moment.updateLocale('hu', { week: { dow: 1, }, });

        const handleError = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error): void => {
            console.error('Futás közben kezeletlen hiba történt.');
            if (source)
                console.error('Forrás          :::', source);
            if (lineno)
                console.error('Sor index       :::', lineno);
            if (colno)
                console.error('Karakter index  :::', colno);
            if (error?.name)
                console.error('Hiba megnevezés :::', error?.name);
            if (error?.message)
                console.error('Hiba üzenet     :::', error?.message);
            if (error?.cause)
                console.error('Hiba ok         :::', error?.cause);
            if (error?.stack)
                console.error('Hiba verem      :::', error?.stack);
            console.error('Esemény szöveg  :::', event.toString());
            console.error('Esemény érték   :::', event.valueOf());
            toast.error(event.toString());
        };

        window.addEventListener('error', handleError);

        return () => {
            window.removeEventListener('error', handleError);
        }
    }, []);

    useEffect((): void => {
        const isLoggedInTemp: boolean = AuthenticationService.loggedInState();
        const shouldRedirectToHome: boolean = (isLoggedInTemp) && (exceptPaths.includes(pathname));
        const shouldRedirectToLogin: boolean = (!isLoggedInTemp) && (!exceptPaths.includes(pathname));

        setIsLoggedIn(isLoggedInTemp);

        if (shouldRedirectToHome) {
            navigate(getAppRoutes.home);
        } else if (shouldRedirectToLogin) {
            navigate(getAppRoutes.login);
        }
    }, [navigate, pathname]);

    // --------------------------------------------------------------Styles---
    const boxStyles: CSSPropertiesModel = {
        main: {
            display: 'flex',
            flexDirection: 'column',
        },
        subBottomRow: {
            display: 'flex',
        },
        subBottomRowMain: {
            flexGrow: 1,
        },
    }

    const boxSx = (theme: Theme, isDrawerOpened: boolean, drawerWidth: number): SxPropsModel => {
        return {
            subBottomRowMain: {
                ...((isDrawerOpened) && {
                    maxWidth: `calc(100% - ${drawerWidth}px)`,
                }),
                ...((!isDrawerOpened) && {
                    maxWidth: `calc(100% - ${theme.spacing(7)} - 1px)`,
                    [theme.breakpoints.up('sm')]: {
                        maxWidth: `calc(100% - ${theme.spacing(8)} - 1px)`,
                    },
                }),
                p: theme.spacing(4),
                [theme.breakpoints.down('lg')]: {
                    p: theme.spacing(2),
                },
                [theme.breakpoints.down('md')]: {
                    p: 0,
                },
            },
        }
    }

    // --------------------------------------------------------------Return---
    return (
        <ThemeProvider theme={currentTheme}>
            <Box style={boxStyles.main}>
                <CssBaseline enableColorScheme />
                <ToastContainer
                    pauseOnFocusLoss={false}
                    position={'bottom-right'}
                    theme={isDarkMode ? ThemeModeEnumerator.Dark : ThemeModeEnumerator.Light}
                />
                {(isLoggedIn) && (
                    <HeaderComponent
                        callbackIsDarkMode={setIsDarkMode}
                        callbackIsDrawerOpened={setIsDrawerOpened}
                        isDarkMode={isDarkMode}
                    />
                )}
                <Box style={boxStyles.subBottomRow}>
                    {(isLoggedIn) && (
                        <DrawerComponent
                            callbackIsOpened={setIsDrawerOpened}
                            isOpened={isDrawerOpened}
                            width={drawerWidth}
                        />
                    )}
                    <Box
                        component={'main'}
                        style={boxStyles.subBottomRowMain}
                        sx={boxSx(theme, isDrawerOpened, drawerWidth).subBottomRowMain}
                    >
                        <Routes>
                            <Route element={<CountriesPage />} path={getAppRoutes.masterData.countries} />
                            <Route element={<GroupsPage />} path={getAppRoutes.masterData.groups} />
                            <Route element={<HomePage />} path={getAppRoutes.home} />
                            <Route element={<LoginPage />} path={getAppRoutes.login} />
                            <Route element={<NotFoundPage />} path={getAppRoutes.notFound} />
                            <Route element={<PartnersPage />} path={getAppRoutes.masterData.partners} />
                            <Route element={<ProductsPage />} path={getAppRoutes.masterData.products} />
                            <Route element={<SaleInvoicePage />} path={getAppRoutes.sale.invoice} />
                            <Route element={<ServiceListsPage />} path={getAppRoutes.masterData.serviceLists} />
                            <Route element={<UnitOfMeasuresPage />} path={getAppRoutes.masterData.unitOfMeasures} />
                            <Route element={<VatRatesPage />} path={getAppRoutes.masterData.vatRates} />
                        </Routes>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

// -----------------------------------------------------------------Exports---
export default App;
