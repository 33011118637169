// -----------------------------------------------------------------Imports---
import {
    Dialog,
    Divider,
    Theme,
    useTheme,
} from '@mui/material';

import DialogTransitionComponent from './DialogTransitionComponent';

import SubDialogActionsComponent from './components/SubDialogActionsComponent';
import SubDialogContentComponent from './components/SubDialogContentComponent';
import SubDialogTitleComponent from './components/SubDialogTitleComponent';

import DialogTypeEnumerator from '../../enumerators/DialogTypeEnumerator';

import CSSPropertiesModel from '../../models/CSSPropertiesModel';
import SxPropsModel from '../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackClickClose: () => void;
    callbackClickSave: () => void;
    contentFields: JSX.Element;
    dataToBeRemoved: string;
    isOpened: boolean;
    type: DialogTypeEnumerator;
}

/**
 * Általános felugró 'Dialog' ablak törzs jellegű adatok hozzáadásához,
 * módosításához, törléséhez.
 * @param callbackClickClose 'Dialog' bezárás esemény
 * @param callbackClickSave 'Dialog' mentés esemény
 * @param contentFields Hozzáadási, módosítási üzem esetén kitöltendő
 * komponensek
 * @param dataToBeRemoved Törlési üzem esetén törlendő adat
 * @param isOpened 'Dialog' megjelenítés állapot
 * @param type 'Dialog' típus
 */
const DialogComponent = ({
    callbackClickClose,
    callbackClickSave,
    contentFields,
    dataToBeRemoved,
    isOpened,
    type,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();

    // --------------------------------------------------------------Styles---
    const paperStyles: CSSPropertiesModel = {
        main: {
            maxWidth: 'initial',
        },
    }

    const paperSx: SxPropsModel = {
        main: {
            width: '50%',
            [theme.breakpoints.down('lg')]: {
                width: '75%',
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Dialog
            open={isOpened}
            PaperProps={{
                style: paperStyles.main,
                sx: paperSx.main,
            }}
            TransitionComponent={DialogTransitionComponent}
        >
            <SubDialogTitleComponent title={type.toString()} />
            <Divider variant={'middle'} />
            <SubDialogContentComponent
                dataToBeRemoved={dataToBeRemoved}
                fields={contentFields}
                type={type}
            />
            <Divider variant={'middle'} />
            <SubDialogActionsComponent
                callbackClickClose={callbackClickClose}
                callbackClickSave={callbackClickSave}
                type={type}
            />
        </Dialog>
    );
}

// -----------------------------------------------------------------Exports---
export default DialogComponent;
