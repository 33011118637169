// -----------------------------------------------------------------Imports---
import {
    Dispatch,
    SetStateAction,
} from 'react';

import {
    AppBar,
    Grid,
    Theme,
    Toolbar,
    useTheme,
} from '@mui/material';

import CSSPropertiesModel from '../../models/CSSPropertiesModel';
import SxPropsModel from '../../models/SxPropsModel';

import SubLeftSideComponent from './components/SubLeftSideComponent';
import SubMiddleComponent from './components/SubMiddleComponent';
import SubRightSideComponent from './components/SubRightSideComponent';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackIsDarkMode: Dispatch<SetStateAction<boolean>>;
    callbackIsDrawerOpened: Dispatch<SetStateAction<boolean>>;
    isDarkMode: boolean;
}

/**
 * FIXME: Dispatch helyett callback-et kell használni
 * Fej sor
 * @param callbackIsDarkMode Téma állapot esemény
 * @param callbackIsDrawerOpened Navigáció megjelenítés állapot esemény
 * @param isDarkMode Alkalmazás téma állapot
 */
const HeaderComponent = ({
    callbackIsDarkMode,
    callbackIsDrawerOpened,
    isDarkMode,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();

    // --------------------------------------------------------------Styles---
    const appBarStyles: CSSPropertiesModel = {
        main: {
            position: 'sticky',
        },
    }

    const appBarSx = (theme: Theme): SxPropsModel => {
        return {
            main: {
                zIndex: theme.zIndex.drawer + 1,
            },
        }
    }

    const gridStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <AppBar
            component={'header'}
            style={appBarStyles.main}
            sx={appBarSx(theme).main}
        >
            <Toolbar>
                <Grid
                    container
                    style={gridStyles.main}
                >
                    <SubLeftSideComponent callbackIsDrawerOpened={callbackIsDrawerOpened} />

                    <SubMiddleComponent />

                    <SubRightSideComponent
                        isDarkMode={isDarkMode}
                        callbackDarkMode={callbackIsDarkMode}
                    />
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

// -----------------------------------------------------------------Exports---
export default HeaderComponent;
