// -----------------------------------------------------------------Imports---
import { Key } from 'react';

import {
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Grid,
    Theme,
    Typography,
} from '@mui/material';

import ProductEntity from '../../../../entities/ProductEntity';
import ServiceListEntity from '../../../../entities/ServiceListEntity';

import ThemeModeEnumerator from '../../../../enumerators/ThemeModeEnumerator';

import notFoundDarkImage from '../../../../images/notFoundDark.png';
import notFoundLightImage from '../../../../images/notFoundLight.png';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackClickProduct: (product: ProductEntity | ServiceListEntity) => void;
    products: (ProductEntity | ServiceListEntity)[];
    theme: Theme;
}

/**
 * Temék szűrő
 * @param callbackClickProduct Termék esemény
 * @param products Termékek
 * @param theme Téma
 */
const SubProductListComponent = ({
    callbackClickProduct,
    products,
    theme,
}: Props): JSX.Element => {
    // --------------------------------------------------------------Styles---
    const cardActionAreaStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            height: '100%',
            padding: 4,
        },
    }

    const cardContentStyles: CSSPropertiesModel = {
        main: {
            fontSize: '125%',
            fontWeight: 'bold',
            textAlign: 'center',
            wordWrap: 'break-word',
        },
    }

    const cardHeaderStyles: CSSPropertiesModel = {
        main: {
            flexGrow: 1,
            textAlign: 'center',
            wordBreak: 'break-word',
        },
    }

    const cardMediaStyles: CSSPropertiesModel = {
        main: {
            height: 80,
            objectFit: 'contain',
            pointerEvents: 'none',
        }
    }

    const cardStyles: CSSPropertiesModel = {
        main: {
            height: '100%',
        },
    }

    const gridStyles: CSSPropertiesModel = {
        main: {
            alignContent: 'flex-start',
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        sub: {
            padding: 4,
            transition: 'transform 0.2s',
        },
    }

    const gridSx: SxPropsModel = {
        sub: {
            '&:hover': {
                transform: 'scale(1.05)',
            },
        },
    }

    const typographyStyles: CSSPropertiesModel = {
        main: {
            fontSize: '90%',
            textAlign: 'center',
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Grid
            item container
            style={gridStyles.main}
        >
            {products.map((product: ProductEntity | ServiceListEntity, productKey: Key): JSX.Element => {
                return (
                    <Grid
                        item
                        key={productKey}
                        style={gridStyles.sub}
                        sx={gridSx.sub}
                        lg={2} md={3} sm={6} xs={12}
                    >
                        <Card
                            style={cardStyles.main}
                        >
                            <CardActionArea
                                onClick={(): void => callbackClickProduct(product)}
                                style={cardActionAreaStyles.main}
                            >
                                <Typography style={typographyStyles.main}>
                                    {product.barcode}
                                </Typography>
                                <Divider variant={'middle'} />
                                <CardMedia
                                    alt={product.barcode}
                                    component={'img'}
                                    image={theme.palette.mode === ThemeModeEnumerator.Dark ? notFoundDarkImage : notFoundLightImage}
                                    style={cardMediaStyles.main}
                                />
                                <Divider variant={'middle'} />
                                <CardHeader
                                    style={cardHeaderStyles.main}
                                    title={product.name}
                                />
                                <Divider variant={'middle'} />
                                <CardContent style={cardContentStyles.main}>
                                    {product.grossSalesPrice?.toLocaleString()} Ft
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
}

// -----------------------------------------------------------------Exports---
export default SubProductListComponent;
