// -----------------------------------------------------------------Imports---
import {
    ChangeEvent,
    Key,
} from 'react';

import {
    Add,
    Delete,
    Remove,
} from '@mui/icons-material';

import {
    Card,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';

import { SaleItem } from '../SaleInvoicePage';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackClickAdd: (saleItem: SaleItem) => void;
    callbackClickDelete: (saleItem: SaleItem) => void;
    callbackChangeQuantity: (event: ChangeEvent<HTMLInputElement>, saleItem: SaleItem) => void;
    callbackClickRemove: (saleItem: SaleItem) => void;
    selectedSaleItems: SaleItem[];
}

/**
 * Kiválasztott számla termékek
 * @param callbackClickAdd Kiválasztott számla termék mennyiség növelő esemény
 * @param callbackClickDelete Kiválasztott számla termék törlő esemény
 * @param callbackChangeQuantity Kiválasztott számla termék mennyiség esemény
 * @param callbackClickRemove Kiválasztott számla termék mennyiség csökkentő esemény
 * @param selectedSaleItems Kiválasztott termékek
 */
const SubSaleItemListComponent = ({
    callbackClickAdd,
    callbackClickDelete,
    callbackChangeQuantity,
    callbackClickRemove,
    selectedSaleItems,
}: Props): JSX.Element => {
    // --------------------------------------------------------------Styles---
    const gridStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            flexDirection: 'column',
            flexGrow: 1,
            flexWrap: 'nowrap',
            gap: 8,
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        subFirstRowFirstColumn: {
            padding: 4,
            wordWrap: 'break-word',
        },
        subFirstRowSecondColumn: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            overflowWrap: 'anywhere',
            padding: 4,
            textAlign: 'right',
        },
        subSecondRowFirstColumn: {
            alignItems: 'center',
            display: 'flex',
            gap: 8,
            padding: 4,
        },
        subSecondRowSecondColumn: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 4,
        },
        subMain: {
            overflow: 'initial',
            padding: 8,
            transition: 'transform 0.2s',
        }
    }

    const gridSx: SxPropsModel = {
        subMain: {
            '&:hover': {
                transform: 'scale(1.05)',
            },
        },
    }

    const iconButtonStyles: CSSPropertiesModel = {
        main: {
            borderRadius: 8,
            borderStyle: 'solid',
            borderWidth: 1,
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Grid
            item container
            style={gridStyles.main}
        >
            {selectedSaleItems.map((saleItem: SaleItem, saleItemKey: Key): JSX.Element => {
                return (
                    <Grid
                        item container
                        component={Card}
                        elevation={8}
                        key={saleItemKey}
                        style={gridStyles.subMain}
                        sx={gridSx.subMain}
                    >
                        <Grid
                            item
                            style={gridStyles.subFirstRowFirstColumn}
                            xs={8}
                        >
                            <Typography>
                                {saleItem.product?.name}
                            </Typography>
                            <Typography>
                                {`${saleItem.product?.grossSalesPrice?.toLocaleString()} Ft / ${saleItem.product?.unitOfMeasure?.shortName}`}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            style={gridStyles.subFirstRowSecondColumn}
                            xs={4}
                        >
                            <Typography>
                                {`${(Number(saleItem.product?.grossSalesPrice) * saleItem.quantity).toLocaleString()} Ft`}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            style={gridStyles.subSecondRowFirstColumn}
                            xs={8}
                        >
                            <IconButton
                                color={'secondary'}
                                onClick={(): void => callbackClickRemove(saleItem)}
                                style={iconButtonStyles.main}
                            >
                                <Remove />
                            </IconButton>
                            <TextField
                                fullWidth
                                label={'Mennyiség'}
                                onChange={(e: ChangeEvent<HTMLInputElement>): void => callbackChangeQuantity(e, saleItem)}
                                value={saleItem.quantity}
                                type={'number'}
                            />
                            <IconButton
                                color={'success'}
                                onClick={(): void => callbackClickAdd(saleItem)}
                                style={iconButtonStyles.main}
                            >
                                <Add />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            style={gridStyles.subSecondRowSecondColumn}
                            xs={4}
                        >
                            <IconButton
                                color={'error'}
                                onClick={(): void => callbackClickDelete(saleItem)}
                                style={iconButtonStyles.main}
                            >
                                <Delete />
                            </IconButton>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
}

// -----------------------------------------------------------------Exports---
export default SubSaleItemListComponent;
