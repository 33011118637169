// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import DeliveryAddressEntity from '../../entities/DeliveryAddressEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import DeliveryAddressAddOrUpdateRequestModel from '../../models/deliveryAddress/DeliveryAddressAddOrUpdateRequestModel';
import DeliveryAddressListRequestModel from '../../models/deliveryAddress/DeliveryAddressListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getDeliveryAddresses(data?: DeliveryAddressListRequestModel): Promise<ResponseModel<DeliveryAddressEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.deliveryAddress.getDeliveryAddresses,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async addDeliveryAddress(data: DeliveryAddressAddOrUpdateRequestModel): Promise<ResponseModel<DeliveryAddressEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.deliveryAddress.addDeliveryAddress,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async updateDeliveryAddress(id: number, data?: DeliveryAddressAddOrUpdateRequestModel): Promise<ResponseModel<DeliveryAddressEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.deliveryAddress.updateDeliveryAddress.replace('{id}', id.toString()),
            'PUT',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async removeDeliveryAddress(id: number): Promise<ResponseModel<string>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.deliveryAddress.removeDeliveryAddress.replace('{id}', id.toString()),
            'DELETE',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        );
    }
}

const DeliveryAddressService = new LocalService();

// -----------------------------------------------------------------Exports---
export default DeliveryAddressService;
