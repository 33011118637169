// ----------------------------------------------------------------Privates---
interface GroupAddOrUpdateRequestModel {
    name?: string;
}

// -----------------------------------------------------------------Exports---
export const initialGroupAddOrUpdateRequestModel: GroupAddOrUpdateRequestModel = {
    name: '',
}

export default GroupAddOrUpdateRequestModel;
