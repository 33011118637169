// -----------------------------------------------------------------Imports---
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useState,
} from 'react';

import {
    Close,
    Save,
} from '@mui/icons-material';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Theme,
    useTheme,
} from '@mui/material';

import DialogTransitionComponent from '../../../../components/dialog/DialogTransitionComponent';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackIsOpened: (value: boolean) => void;
    callbackSelectedComment: (value: string) => void;
    isOpened: boolean;
    selectedComment: string;
}

/**
 * Számla megjegyzés
 * @param callbackIsOpened Nyitott állapot esemény
 * @param callbackSelectedComment Kiválasztott megjegyzés esemény
 * @param isOpened Nyitott állapot
 * @param selectedComment Kiválasztott megjegyzés
 */
const SubDialogCommentComponent = ({
    callbackIsOpened,
    callbackSelectedComment,
    isOpened,
    selectedComment,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [thisComment, setThisComment]: [string, Dispatch<SetStateAction<string>>] = useState<string>(selectedComment);

    // --------------------------------------------------------------Events---
    const handleChangeComment = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setThisComment(event.target.value);
    }, []);

    const handleClickClose = useCallback((): void => {
        setThisComment(selectedComment);
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        selectedComment,
    ]);

    const handleClickSave = useCallback((): void => {
        callbackSelectedComment(thisComment);
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        callbackSelectedComment,
        thisComment,
    ]);

    // --------------------------------------------------------------Styles---
    const dialogActionsStyles: CSSPropertiesModel = {
        main: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }

    const dialogContentStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
        },
    }

    const dialogTitleStyles: CSSPropertiesModel = {
        main: {
            textAlign: 'center',
            userSelect: 'none',
        },
    }

    const paperStyles: CSSPropertiesModel = {
        main: {
            maxWidth: 'initial',
        },
    }

    const paperSx: SxPropsModel = {
        main: {
            width: '50%',
            [theme.breakpoints.down('lg')]: {
                width: '75%',
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        }
    }

    // --------------------------------------------------------------Return---
    return (
        <Dialog
            open={isOpened}
            PaperProps={{
                style: paperStyles.main,
                sx: paperSx.main,
            }}
            TransitionComponent={DialogTransitionComponent}
        >
            <DialogTitle style={dialogTitleStyles.main}>
                Megjegyzés hozzáadás
            </DialogTitle>
            <Divider variant={'middle'} />
            <DialogContent style={dialogContentStyles.main}>
                <TextField
                    autoFocus
                    fullWidth
                    label={'Megjegyzés'}
                    multiline
                    onChange={handleChangeComment}
                    value={thisComment}
                />
            </DialogContent>
            <Divider variant={'middle'} />
            <DialogActions style={dialogActionsStyles.main}>
                <Button
                    onClick={handleClickClose}
                    startIcon={<Close />}
                    variant={'outlined'}
                >
                    Mégsem
                </Button>
                <Button
                    color={'success'}
                    onClick={handleClickSave}
                    startIcon={<Save />}
                    variant={'contained'}
                >
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// -----------------------------------------------------------------Exports---
export default SubDialogCommentComponent;
