// -----------------------------------------------------------------Imports---
import {
    getCLS,
    getFCP,
    getFID,
    getLCP,
    getTTFB,
    ReportHandler,
} from 'web-vitals';

// ----------------------------------------------------------------Privates---
const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
    if (
        (onPerfEntry)
        && (onPerfEntry instanceof Function)
    ) {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
    }
}

// -----------------------------------------------------------------Exports---
export default reportWebVitals;
