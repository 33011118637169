// ----------------------------------------------------------------Privates---
interface DeliveryAddressAddOrUpdateRequestModel {
    partnerId?: number;
    name?: string;
    postCode?: string;
    city?: string;
    streetName?: string;
    streetType?: string;
    houseNumber?: string;
    telephone?: string;
    email?: string;
    contactName?: string;
    contactTelephone?: string;
    contactEmail?: string;
    comment?: string;
    countryId?: number;
}

// -----------------------------------------------------------------Exports---
export const initialDeliveryAddressAddOrUpdateRequestModel: DeliveryAddressAddOrUpdateRequestModel = {
    partnerId: undefined,
    name: '',
    postCode: '',
    city: '',
    streetName: '',
    streetType: '',
    houseNumber: '',
    telephone: '',
    email: '',
    contactName: '',
    contactTelephone: '',
    contactEmail: '',
    comment: '',
    countryId: undefined,
}

export default DeliveryAddressAddOrUpdateRequestModel;
