// -----------------------------------------------------------------Imports---
import {
    Dispatch,
    SetStateAction,
    SyntheticEvent,
    useCallback,
    useState,
} from 'react';

import {
    Close,
    Save,
} from '@mui/icons-material';

import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Theme,
    useTheme,
} from '@mui/material';

import { partnerOrDeliveryAddressToFullAddress } from '../../../../CodeKit';

import DialogTransitionComponent from '../../../../components/dialog/DialogTransitionComponent';

import DeliveryAddressEntity from '../../../../entities/DeliveryAddressEntity';
import PartnerEntity from '../../../../entities/PartnerEntity';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackIsOpened: (value: boolean) => void;
    callbackSelectedDeliveryAddress: (value: DeliveryAddressEntity | null) => void;
    isOpened: boolean;
    partners: PartnerEntity[];
    selectedDeliveryAddress: DeliveryAddressEntity | null;
}

interface State {
    thisDeliveryAddress: DeliveryAddressEntity | null;
    thisPartner: PartnerEntity | null;
}

const initialState: State = {
    thisDeliveryAddress: null,
    thisPartner: null,
}

/**
 * Számla szállítási cím
 * @param callbackIsOpened Nyitott állapot esemény
 * @param callbackSelectedDeliveryAddress Kiválasztott szállítási cím esemény
 * @param isOpened Nyitott állapot
 * @param partners Partnerek
 * @param selectedDeliveryAddress Kiválasztott szállítási cím
 */
const SubDialogDeliveryAddressComponent = ({
    callbackIsOpened,
    callbackSelectedDeliveryAddress,
    isOpened,
    partners,
    selectedDeliveryAddress,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);

    // --------------------------------------------------------------Events---
    const handleChangePartner = useCallback((event: SyntheticEvent, newValue: PartnerEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            thisDeliveryAddress: null,
            thisPartner: newValue,
        }));
    }, []);

    const handleChangeDeliveryAddress = useCallback((event: SyntheticEvent, newValue: DeliveryAddressEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            thisDeliveryAddress: newValue,
        }));
    }, []);

    const handleClickClose = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            thisDeliveryAddress: selectedDeliveryAddress,
            thisPartner: selectedDeliveryAddress?.partner ?? null,
        }));
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        selectedDeliveryAddress,
    ]);

    const handleClickSave = useCallback((): void => {
        callbackSelectedDeliveryAddress(state.thisDeliveryAddress);
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        callbackSelectedDeliveryAddress,
        state.thisDeliveryAddress,
    ]);

    // --------------------------------------------------------------Styles---
    const dialogActionsStyles: CSSPropertiesModel = {
        main: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }

    const dialogContentStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
        },
    }

    const dialogTitleStyles: CSSPropertiesModel = {
        main: {
            textAlign: 'center',
            userSelect: 'none',
        },
    }

    const paperStyles: CSSPropertiesModel = {
        main: {
            maxWidth: 'initial',
        },
    }

    const paperSx: SxPropsModel = {
        main: {
            width: '50%',
            [theme.breakpoints.down('lg')]: {
                width: '75%',
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Dialog
            open={isOpened}
            PaperProps={{
                style: paperStyles.main,
                sx: paperSx.main,
            }}
            TransitionComponent={DialogTransitionComponent}
        >
            <DialogTitle style={dialogTitleStyles.main}>
                Szállítási cím kiválasztás
            </DialogTitle>
            <Divider variant={'middle'} />
            <DialogContent style={dialogContentStyles.main}>
                <Autocomplete
                    autoComplete
                    filterSelectedOptions
                    fullWidth
                    getOptionLabel={(option: PartnerEntity) => `${option.name} [${partnerOrDeliveryAddressToFullAddress(option)}] (${option.id?.toString()})` ?? ''}
                    onChange={handleChangePartner}
                    options={partners}
                    renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Partnerek'} />}
                    value={state.thisPartner}
                />
                <Autocomplete
                    autoComplete
                    disabled={state.thisPartner === null}
                    filterSelectedOptions
                    fullWidth
                    getOptionLabel={(option: DeliveryAddressEntity) => `${option.name} [${partnerOrDeliveryAddressToFullAddress(option)}] (${option.id?.toString()})` ?? ''}
                    onChange={handleChangeDeliveryAddress}
                    options={state.thisPartner?.deliveryAddresses || []}
                    renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Telephelyek'} />}
                    value={state.thisDeliveryAddress}
                />
            </DialogContent>
            <Divider variant={'middle'} />
            <DialogActions style={dialogActionsStyles.main}>
                <Button
                    onClick={handleClickClose}
                    startIcon={<Close />}
                    variant={'outlined'}
                >
                    Mégsem
                </Button>
                <Button
                    color={'success'}
                    disabled={state.thisDeliveryAddress === null}
                    onClick={handleClickSave}
                    startIcon={<Save />}
                    variant={'contained'}
                >
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// -----------------------------------------------------------------Exports---
export default SubDialogDeliveryAddressComponent;
