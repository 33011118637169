// ----------------------------------------------------------------Privates---
interface ServiceListAddOrUpdateRequestModel {
    barcode?: string;
    name?: string;
    serviceListNumber?: string;
    groupId?: number;
    vatRateId?: number;
    unitOfMeasureId?: number;
    netSalesPrice?: number;
    grossSalesPrice?: number;
    isMediatedService?: boolean;
}

// -----------------------------------------------------------------Exports---
export const initialServiceListAddOrUpdateRequestModel: ServiceListAddOrUpdateRequestModel = {
    barcode: '',
    name: '',
    serviceListNumber: '',
    groupId: undefined,
    vatRateId: undefined,
    unitOfMeasureId: undefined,
    netSalesPrice: 0,
    grossSalesPrice: 0,
    isMediatedService: false,
}

export default ServiceListAddOrUpdateRequestModel;
