// -----------------------------------------------------------------Imports---
import {
    Divider,
    Grid,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';

import ThemeModeEnumerator from '../../../../enumerators/ThemeModeEnumerator';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
/**
 * Kiválasztott számla termékek fej sor
 */
const SubSaleItemListHeaderComponent = (): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();

    // --------------------------------------------------------------Styles---
    const gridStyles: CSSPropertiesModel = {
        main: {
            flexDirection: 'column',
            gap: 8,
        },
    }

    const typographySx = (theme: Theme): SxPropsModel => {
        return {
            main: {
                backgroundColor: 'info.main',
                borderColor: 'info.main',
                borderRadius: '4px',
                borderStyle: 'solid',
                borderWidth: '1px',
                color: theme.palette.mode === ThemeModeEnumerator.Dark ? 'black' : 'white',
                fontSize: '0.875rem',
                textTransform: 'uppercase',
                paddingBottom: '5px',
                paddingLeft: '15px',
                paddingRight: '15px',
                paddingTop: '5px',
                textAlign: 'center',
                userSelect: 'none',
            },
        }
    }

    // --------------------------------------------------------------Return---
    return (
        <Grid
            item container
            style={gridStyles.main}
        >
            <Grid item>
                <Typography sx={typographySx(theme).main}>
                    Kiválasztott termékek
                </Typography>
            </Grid>
            <Grid item>
                <Divider variant={'middle'} />
            </Grid>
        </Grid>
    );
}

// -----------------------------------------------------------------Exports---
export default SubSaleItemListHeaderComponent;
