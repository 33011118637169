// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import VatRateEntity from '../../entities/VatRateEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import VatRateAddOrUpdateRequestModel from '../../models/vatRate/VatRateAddOrUpdateRequestModel';
import VatRateListRequestModel from '../../models/vatRate/VatRateListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getVatRates(data?: VatRateListRequestModel): Promise<ResponseModel<VatRateEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.vatRate.getVatRates,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async addVatRate(data: VatRateAddOrUpdateRequestModel): Promise<ResponseModel<VatRateEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.vatRate.addVatRate,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async updateVatRate(id: number, data?: VatRateAddOrUpdateRequestModel): Promise<ResponseModel<VatRateEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.vatRate.updateVatRate.replace('{id}', id.toString()),
            'PUT',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async removeVatRate(id: number): Promise<ResponseModel<string>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.vatRate.removeVatRate.replace('{id}', id.toString()),
            'DELETE',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        );
    }
}

const VatRateService = new LocalService();

// -----------------------------------------------------------------Exports---
export default VatRateService;
