// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import PaymentMethodEntity from '../../entities/PaymentMethodEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import PaymentMethodAddOrUpdateRequestModel from '../../models/paymentMethod/PaymentMethodAddOrUpdateRequestModel';
import PaymentMethodListRequestModel from '../../models/paymentMethod/PaymentMethodListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getPaymentMethods(data?: PaymentMethodListRequestModel): Promise<ResponseModel<PaymentMethodEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.paymentMethod.getPaymentMethods,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async addPaymentMethod(data: PaymentMethodAddOrUpdateRequestModel): Promise<ResponseModel<PaymentMethodEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.paymentMethod.addPaymentMethod,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async updatePaymentMethod(id: number, data?: PaymentMethodAddOrUpdateRequestModel): Promise<ResponseModel<PaymentMethodEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.paymentMethod.updatePaymentMethod.replace('{id}', id.toString()),
            'PUT',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async removePaymentMethod(id: number): Promise<ResponseModel<string>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.paymentMethod.removePaymentMethod.replace('{id}', id.toString()),
            'DELETE',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        );
    }
}

const PaymentMethodService = new LocalService();

// -----------------------------------------------------------------Exports---
export default PaymentMethodService;
