// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import ServiceListEntity from '../../entities/ServiceListEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import ServiceListAddOrUpdateRequestModel from '../../models/serviceList/ServiceListAddOrUpdateRequestModel';
import ServiceListListRequestModel from '../../models/serviceList/ServiceListListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getServiceLists(data?: ServiceListListRequestModel): Promise<ResponseModel<ServiceListEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.serviceList.getServiceLists,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async addServiceList(data: ServiceListAddOrUpdateRequestModel): Promise<ResponseModel<ServiceListEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.serviceList.addServiceList,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async updateServiceList(id: number, data?: ServiceListAddOrUpdateRequestModel): Promise<ResponseModel<ServiceListEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.serviceList.updateServiceList.replace('{id}', id.toString()),
            'PUT',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async removeServiceList(id: number): Promise<ResponseModel<string>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.serviceList.removeServiceList.replace('{id}', id.toString()),
            'DELETE',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        );
    }
}

const ServiceListService = new LocalService();

// -----------------------------------------------------------------Exports---
export default ServiceListService;
