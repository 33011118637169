// -----------------------------------------------------------------Imports---
import { Theme } from '@mui/material';

import ThemeModeEnumerator from '../../enumerators/ThemeModeEnumerator';

import CSSPropertiesModel from '../../models/CSSPropertiesModel';
import SxPropsModel from '../../models/SxPropsModel';

// -----------------------------------------------------------------Exports---
export const fabStyles: CSSPropertiesModel = {
    add: {
        bottom: 25,
        position: 'fixed',
        right: 25,
    },
}

export const PaperSxProps = (theme: Theme): SxPropsModel => {
    return {
        filter: {
            marginBottom: theme.spacing(4),
            [theme.breakpoints.down('lg')]: {
                marginBottom: theme.spacing(2),
            },
            [theme.breakpoints.down('md')]: {
                marginBottom: 0,
            },
        },
    }
}

export const TableRowSxProps = (theme: Theme): SxPropsModel => {
    return {
        bodyRow: {
            transition: theme.transitions.create('background', {
                duration: (theme.transitions.duration.enteringScreen) && (theme.transitions.duration.leavingScreen),
                easing: theme.transitions.easing.sharp,
            }),
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.mode === ThemeModeEnumerator.Dark ? '#343434' : '#dcdcdc',
            },
            '&:hover': {
                backgroundColor: theme.palette.mode === ThemeModeEnumerator.Dark ? 'teal' : 'cyan',
            },
        },
    }
}
