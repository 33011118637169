// -----------------------------------------------------------------Imports---
import {
    AttachMoney,
    Category,
    Dataset,
    Factory,
    Handshake,
    Home,
    Inventory,
    Key,
    LegendToggle,
    MiscellaneousServices,
    Money,
    MoneyOff,
    Payments,
    PriceChange,
    PriceCheck,
    ProductionQuantityLimits,
    Public,
    Receipt,
    Schema,
    ShoppingCart,
    ShoppingCartCheckout,
} from '@mui/icons-material';

import { DateOrTimeView } from '@mui/x-date-pickers';

import MenuModel from './models/MenuModel';
import VersionModel from './models/VersionModel';

// ----------------------------------------------------------------Privates---
/**
 * Helyi futási állapot
 */
const isLocal: boolean = false;

// -----------------------------------------------------------------Exports---
/**
 * Globális időbélyeg formátum
 */
export const dateTimeFormat: string = 'YYYY-MM-DD HH:mm:ss';

/**
 * Globális időbélyeg kezelő beállítandó részletek
 */
export const dateTimePickerInputFormats: DateOrTimeView[] = ['year', 'month', 'day', 'hours', 'minutes', 'seconds'];

/**
 * Globális késleltetés
 */
export const debounceDelay: number = 1000;

/**
 * API elérési végpontok
 */
export const getApiRoutes = {
    authentication: '/authentication/login',
    country: {
        addCountry: '/country',
        getCountries: '/country/getCountries',
        updateCountry: '/country/{id}',
        removeCountry: '/country/{id}',
    },
    deliveryAddress: {
        addDeliveryAddress: '/deliveryAddress',
        getDeliveryAddresses: '/deliveryAddress/getDeliveryAddresses',
        updateDeliveryAddress: '/deliveryAddress/{id}',
        removeDeliveryAddress: '/deliveryAddress/{id}',
    },
    deliveryMethod: {
        addDeliveryMethod: '/deliveryMethod',
        getDeliveryMethods: '/deliveryMethod/getDeliveryMethods',
        updateDeliveryMethod: '/deliveryMethod/{id}',
        removeDeliveryMethod: '/deliveryMethod/{id}',
    },
    group: {
        addGroup: '/group',
        getGroups: '/group/getGroups',
        updateGroup: '/group/{id}',
        removeGroup: '/group/{id}',
    },
    partner: {
        addPartner: '/partner',
        getPartners: '/partner/getPartners',
        updatePartner: '/partner/{id}',
        removePartner: '/partner/{id}',
    },
    paymentMethod: {
        addPaymentMethod: '/paymentMethod',
        getPaymentMethods: '/paymentMethod/getPaymentMethods',
        updatePaymentMethod: '/paymentMethod/{id}',
        removePaymentMethod: '/paymentMethod/{id}',
    },
    product: {
        addProduct: '/product',
        getProducts: '/product/getProducts',
        updateProduct: '/product/{id}',
        removeProduct: '/product/{id}',
    },
    serviceList: {
        addServiceList: '/serviceList',
        getServiceLists: '/serviceList/getServiceLists',
        updateServiceList: '/serviceList/{id}',
        removeServiceList: '/serviceList/{id}',
    },
    unitOfMeasure: {
        addUnitOfMeasure: '/unitOfMeasure',
        getUnitOfMeasures: '/unitOfMeasure/getUnitOfMeasures',
        updateUnitOfMeasure: '/unitOfMeasure/{id}',
        removeUnitOfMeasure: '/unitOfMeasure/{id}',
    },
    user: {
        getUsers: '/user/getUsers',
    },
    vatRate: {
        addVatRate: '/vatRate',
        getVatRates: '/vatRate/getVatRates',
        updateVatRate: '/vatRate/{id}',
        removeVatRate: '/vatRate/{id}',
    },
}

/**
 * API elérési útvonal
 */
export const getApiUrlBase = (): string => {
    return isLocal
        ? 'http://stockwatch.api-test.localserver.com'
        : isTest
            ? 'https://stockwatch.api-test.szihome.hu'
            : 'https://stockwatch.api.szihome.hu';
}

/**
 * Alkalmazás elérési útvonalak
 */
export const getAppRoutes = {
    home: '/',
    login: '/login',
    manufacturing: '/manufacturing',
    masterData: {
        countries: '/masterData/countries',
        groups: '/masterData/groups',
        unitOfMeasures: '/masterData/unitOfMeasures',
        partners: '/masterData/partners',
        products: '/masterData/products',
        serviceLists: '/masterData/serviceLists',
        vatRates: '/masterData/vatRates',
    },
    notFound: '*',
    pricing: {
        discount: '/pricing/discount',
        individualPrice: '/pricing/individualPrice',
        priceChange: '/pricing/priceChange',
    },
    purchase: {
        cancellationInvoice: '/purchase/cancellationInvoice',
        invoice: '/purchase/invoice',
        order: '/purchase/order',
    },
    sale: {
        cancellationInvoice: '/sale/cancellationInvoice',
        invoice: '/sale/invoice',
        order: '/sale/order',
        priceOffer: '/sale/priceOffer',
    },
    stockManagement: '/stockManagement',
    user: '/user',
}

/**
 * Globális teszt állapot
 */
export const isTest: boolean = true;

/**
 * Menü rendszer
 */
export const menus: MenuModel[] = [
    {
        name: 'Kezdőlap',
        route: getAppRoutes.home,
        icon: <Home />,
    },
    {
        name: 'Eladás',
        icon: <AttachMoney />,
        subMenu: [
            {
                name: 'Számla',
                route: getAppRoutes.sale.invoice,
                icon: <Receipt />,
            },
            {
                name: 'Sztornó számla',
                route: getAppRoutes.sale.cancellationInvoice,
                icon: <Receipt />,
            },
            {
                name: 'Megrendelés',
                route: getAppRoutes.sale.order,
                icon: <ShoppingCartCheckout />,
            },
            {
                name: 'Árajánlat',
                route: getAppRoutes.sale.priceOffer,
                icon: <Payments />,
            },
        ],
    },
    {
        name: 'Beszerzés',
        icon: <ShoppingCart />,
        subMenu: [
            {
                name: 'Számla',
                route: getAppRoutes.purchase.invoice,
                icon: <Receipt />,
            },
            {
                name: 'Sztornó számla',
                route: getAppRoutes.purchase.cancellationInvoice,
                icon: <Receipt />,
            },
            {
                name: 'Megrendelés',
                route: getAppRoutes.purchase.order,
                icon: <ShoppingCartCheckout />,
            },
        ],
    },
    {
        name: 'Gyártás',
        route: getAppRoutes.manufacturing,
        icon: <Factory />,
    },
    {
        name: 'Árazás',
        icon: <Money />,
        subMenu: [
            {
                name: 'Árváltozás',
                route: getAppRoutes.pricing.priceChange,
                icon: <PriceChange />,
            },
            {
                name: 'Akció',
                route: getAppRoutes.pricing.discount,
                icon: <MoneyOff />,
            },
            {
                name: 'Egyedi ár',
                route: getAppRoutes.pricing.individualPrice,
                icon: <PriceCheck />,
            },
        ],
    },
    {
        name: 'Készletkezelés',
        route: getAppRoutes.stockManagement,
        icon: <Inventory />,
    },
    {
        name: 'Törzsek',
        icon: <Dataset />,
        subMenu: [
            {
                name: 'Cikkek',
                route: getAppRoutes.masterData.products,
                icon: <ProductionQuantityLimits />,
            },
            {
                name: 'Szolgáltatás jegyzékek',
                route: getAppRoutes.masterData.serviceLists,
                icon: <MiscellaneousServices />,
            },
            {
                name: 'Csoportok',
                route: getAppRoutes.masterData.groups,
                icon: <Category />,
            },
            {
                name: 'ÁFAkulcsok',
                route: getAppRoutes.masterData.vatRates,
                icon: <Key />,
            },
            {
                name: 'Mennyiségi egységek',
                route: getAppRoutes.masterData.unitOfMeasures,
                icon: <Schema />,
            },
            {
                name: 'Partnerek',
                route: getAppRoutes.masterData.partners,
                icon: <Handshake />,
            },
            {
                name: 'Országok',
                route: getAppRoutes.masterData.countries,
                icon: <Public />,
            },
        ],
    },
    {
        name: 'Kimutatások',
        icon: <LegendToggle />,
    },
]

/**
 * Globális keresítési tizedes jegyek száma
 */
export const roundingDigits: number = 4;

/**
 * Verziók
 */
export const versions: VersionModel[] = [
    {
        version: 'v0.0.0.1',
        description: 'Alap verzió kiadása.',
    },
    {
        version: 'v0.0.0.2',
        description: 'Törzs kezelők: országok, csoportok, mennyiségi egységek, partnerek, termékek, szolgáltatás jegyzékek, ÁFAkulcsok.',
    },
    {
        version: 'v0.0.0.3',
        description: 'Szűrők a törzs kezelőkhöz: országok, csoportok, mennyiségi egységek, partnerek, termékek, szolgáltatás jegyzékek, ÁFAkulcsok.',
    },
    {
        version: 'v0.0.0.4',
        description: 'Eladás / Számla lap létrehozása.',
    },
]
