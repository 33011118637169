// -----------------------------------------------------------------Imports---
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { toast } from 'react-toastify';

import {
    DebouncedState,
    useDebounce,
} from 'use-debounce';

import {
    Grid,
    Paper,
    Theme,
    useTheme,
} from '@mui/material';

import SubDialogTranscribedFromProductComponent from './components/SubDialogTranscribedFromProductComponent';
import SubGroupListComponent from './components/SubGroupListComponent';
import SubProductFilteringComponent from './components/SubProductFilteringComponent';
import SubProductListComponent from './components/SubProductListComponent';
import SubSaleItemListComponent from './components/SubSaleItemListComponent';
import SubSaleItemListFooterComponent from './components/SubSaleItemListFooterComponent';
import SubSaleItemListHeaderComponent from './components/SubSaleItemListHeaderComponent';
import SubSaleItemsSummaryComponent from './components/SubSaleItemsSummaryComponent';

import {
    nameSort,
    numberInStringIsValid,
} from '../../../CodeKit';

import { debounceDelay } from '../../../Global';

import DeliveryAddressEntity from '../../../entities/DeliveryAddressEntity';
import DeliveryMethodEntity from '../../../entities/DeliveryMethodEntity';
import GroupEntity from '../../../entities/GroupEntity';
import PartnerEntity from '../../../entities/PartnerEntity';
import PaymentMethodEntity from '../../../entities/PaymentMethodEntity';
import ProductEntity from '../../../entities/ProductEntity';
import ServiceListEntity from '../../../entities/ServiceListEntity';
import UnitOfMeasureEntity from '../../../entities/UnitOfMeasureEntity';
import VatRateEntity from '../../../entities/VatRateEntity';

import CSSPropertiesModel from '../../../models/CSSPropertiesModel';
import FieldValidationErrorModel from '../../../models/FieldValidationErrorModel';
import ResponseModel from '../../../models/ResponseModel';
import ProductFilterRequestModel from '../../../models/product/ProductFilterRequestModel';
import ProductListRequestModel from '../../../models/product/ProductListRequestModel';
import ServiceListFilterRequestModel from '../../../models/serviceList/ServiceListFilterRequestModel';
import ServiceListListRequestModel from '../../../models/serviceList/ServiceListListRequestModel';

import DeliveryMethodService from '../../../services/deliveryMethod/DeliveryMethodService';
import GroupService from '../../../services/group/GroupService';
import PartnerService from '../../../services/partner/PartnerService';
import PaymentMethodService from '../../../services/paymentMethod/PaymentMethodService';
import ProductService from '../../../services/product/ProductService';
import ServiceListService from '../../../services/serviceList/ServiceListService';
import UnitOfMeasureService from '../../../services/unitOfMeasure/UnitOfMeasureService';
import VatRateService from '../../../services/vatRate/VatRateService';

// ----------------------------------------------------------------Privates---
interface FilterState {
    barcode: string;
    name: string;
    groupId?: number;
}

interface SelectedState {
    saleItems: SaleItem[];
    deliveryMethod: DeliveryMethodEntity | null;
    paymentMethod: PaymentMethodEntity | null;
    deliveryAddress: DeliveryAddressEntity | null;
    comment: string;
    date: Date;
}

interface State {
    filterState: FilterState;
    deliveryMethods: DeliveryMethodEntity[];
    groups: GroupEntity[];
    partners: PartnerEntity[];
    paymentMethods: PaymentMethodEntity[];
    products: (ProductEntity | ServiceListEntity)[];
    selected: SelectedState;
    transcribedFromProductIsOpened: boolean;
    unitOfMeasures: UnitOfMeasureEntity[];
    vatRates: VatRateEntity[];
}

const initialState: State = {
    filterState: {
        barcode: '',
        name: '',
        groupId: undefined,
    },
    deliveryMethods: [],
    groups: [],
    partners: [],
    paymentMethods: [],
    products: [],
    selected: {
        saleItems: [],
        deliveryMethod: null,
        paymentMethod: null,
        deliveryAddress: null,
        comment: '',
        date: new Date(),
    },
    transcribedFromProductIsOpened: false,
    unitOfMeasures: [],
    vatRates: [],
}

/**
 * Eladás - Számla
 */
const SaleInvoicePage = (): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);
    const [debouncedState]: [FilterState, DebouncedState<(value: FilterState) => void>] = useDebounce<FilterState>(state.filterState, debounceDelay);

    useEffect((): void => {
        const filterData: ProductFilterRequestModel | ServiceListFilterRequestModel = {
            barcode: debouncedState.barcode?.length === 0 ? undefined : debouncedState.barcode,
            name: debouncedState.name?.length === 0 ? undefined : debouncedState.name,
            groupId: state.filterState.groupId,
        }

        const requestData: ProductListRequestModel | ServiceListListRequestModel = {
            filter: filterData,
        }

        const getProducts = async (): Promise<ProductEntity[]> => {
            const response: ResponseModel<ProductEntity[]> = await ProductService.getProducts(requestData);

            if (response.status === 200) {
                const responseProduct: ProductEntity[] = response.data as ProductEntity[];

                return responseProduct;
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }

                return [];
            }
        }

        const getServiceLists = async (): Promise<ServiceListEntity[]> => {
            const response: ResponseModel<ServiceListEntity[]> = await ServiceListService.getServiceLists(requestData);

            if (response.status === 200) {
                const responseServiceList: ServiceListEntity[] = response.data as ServiceListEntity[];

                return responseServiceList;
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }

                return [];
            }
        }

        const fetchData = async (): Promise<void> => {
            let tempProducts: (ProductEntity | ServiceListEntity)[] = [];

            tempProducts.push(...await getProducts());
            tempProducts.push(...await getServiceLists());

            tempProducts = tempProducts.sort((valueA: ProductEntity | ServiceListEntity, valueB: ProductEntity | ServiceListEntity): number => {
                return nameSort(valueA.name!, valueB.name!);
            });

            setState((prevState: State): State => ({
                ...prevState,
                products: tempProducts,
            }));
        };

        fetchData();
    }, [
        debouncedState.barcode,
        debouncedState.name,
        state.filterState.groupId,
    ]);

    useEffect((): void => {
        const getDeliveryMethods = async (): Promise<void> => {
            const response: ResponseModel<DeliveryMethodEntity[]> = await DeliveryMethodService.getDeliveryMethods();

            if (response.status === 200) {
                const responseDeliveryMethod: DeliveryMethodEntity[] = response.data as DeliveryMethodEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    deliveryMethods: responseDeliveryMethod,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }
            }
        }

        const getGroups = async (): Promise<void> => {
            const response: ResponseModel<GroupEntity[]> = await GroupService.getGroups();

            if (response.status === 200) {
                const responseGroup: GroupEntity[] = response.data as GroupEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    groups: responseGroup,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(field.field + ': ' + field.message);
                    });
                }
            }
        }

        const getPartners = async (): Promise<void> => {
            const response: ResponseModel<PartnerEntity[]> = await PartnerService.getPartners();

            if (response.status === 200) {
                const responsePartner: PartnerEntity[] = response.data as PartnerEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    partners: responsePartner,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(`${field.field}: ${field.message}`);
                    });
                }
            }
        }

        const getPaymentMethods = async (): Promise<void> => {
            const response: ResponseModel<PaymentMethodEntity[]> = await PaymentMethodService.getPaymentMethods();

            if (response.status === 200) {
                const responsePaymentMethod: PaymentMethodEntity[] = response.data as PaymentMethodEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    paymentMethods: responsePaymentMethod,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(`${field.field}: ${field.message}`);
                    });
                }
            }
        }

        const getUnitOfMeasures = async (): Promise<void> => {
            const response: ResponseModel<UnitOfMeasureEntity[]> = await UnitOfMeasureService.getUnitOfMeasures();

            if (response.status === 200) {
                const responseUnitOfMeasure: UnitOfMeasureEntity[] = response.data as UnitOfMeasureEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    unitOfMeasures: responseUnitOfMeasure,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(`${field.field}: ${field.message}`);
                    });
                }
            }
        }

        const getVatRates = async (): Promise<void> => {
            const response: ResponseModel<VatRateEntity[]> = await VatRateService.getVatRates();

            if (response.status === 200) {
                const responseVatRate: VatRateEntity[] = response.data as VatRateEntity[];

                setState((prevState: State): State => ({
                    ...prevState,
                    vatRates: responseVatRate,
                }));
            } else {
                toast.error(response.error?.message);
                if (response.error?.fields) {
                    response.error.fields.forEach((field: FieldValidationErrorModel): void => {
                        toast.error(`${field.field}: ${field.message}`);
                    });
                }
            }
        }

        getDeliveryMethods();
        getGroups();
        getPartners();
        getPaymentMethods();
        getUnitOfMeasures();
        getVatRates();
    }, []);

    // --------------------------------------------------------------Events---
    const handleClickGroupFilter = useCallback((groupId?: number): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                groupId: groupId === state.filterState.groupId
                    ? undefined
                    : groupId,
            },
        }));
    }, [state.filterState.groupId]);

    const handleChangeBarcodeFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                barcode: event.target.value,
            },
        }));
    }, []);

    const handleChangeNameFilter = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setState((prevState: State): State => ({
            ...prevState,
            filterState: {
                ...prevState.filterState,
                name: event.target.value,
            },
        }));
    }, []);

    const handleClickProduct = useCallback((product: ProductEntity | ServiceListEntity): void => {
        if (product.barcode === 'SB0') {
            setState((prevState: State): State => ({
                ...prevState,
                transcribedFromProductIsOpened: true,
            }));

            return;
        }

        const tempItems: SaleItem[] = [...state.selected.saleItems];
        const matchedIndex: number = tempItems.findIndex((item: SaleItem): boolean => item.product === product);

        if (matchedIndex === -1) {
            const saleItem: SaleItem = {
                product: product,
                quantity: 1,
            }

            tempItems.unshift(saleItem);
        } else {
            const saleItem: SaleItem = {
                ...tempItems[matchedIndex],
                quantity: tempItems[matchedIndex].quantity + 1,
            }

            tempItems.splice(matchedIndex, 1);
            tempItems.unshift(saleItem);
        }

        setState((prevState: State): State => ({
            ...prevState,
            selected: {
                ...prevState.selected,
                saleItems: tempItems,
            },
        }));
    }, [state.selected.saleItems]);

    const handleClickAdd = useCallback((saleItem: SaleItem): void => {
        setState((prevState: State): State => ({
            ...prevState,
            selected: {
                ...prevState.selected,
                saleItems: state.selected.saleItems.map((item: SaleItem): SaleItem => {
                    return item === saleItem
                        ? {
                            ...item,
                            quantity: item.quantity + 1,
                        }
                        : item;
                }),
            },
        }));
    }, [state.selected.saleItems]);

    const handleClickRemove = useCallback((saleItem: SaleItem): void => {
        setState((prevState: State): State => ({
            ...prevState,
            selected: {
                ...prevState.selected,
                saleItems: state.selected.saleItems
                    .map((item: SaleItem): SaleItem => {
                        return item === saleItem
                            ? {
                                ...item,
                                quantity: item.quantity - 1,
                            }
                            : item;
                    })
                    .filter((item: SaleItem): boolean => item.quantity !== 0),
            },
        }));
    }, [state.selected.saleItems]);

    const handleClickDelete = useCallback((saleItem: SaleItem): void => {
        setState((prevState: State): State => ({
            ...prevState,
            selected: {
                ...prevState.selected,
                saleItems: state.selected.saleItems.filter((item: SaleItem): boolean => item !== saleItem),
            }
        }));
    }, [state.selected.saleItems]);

    const handleChangeQuantity = useCallback((event: ChangeEvent<HTMLInputElement>, saleItem: SaleItem): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        setState((prevState: State): State => ({
            ...prevState,
            selected: {
                ...prevState.selected,
                saleItems: state.selected.saleItems.map((item: SaleItem): SaleItem => {
                    return item === saleItem
                        ? {
                            ...item,
                            quantity: Number(event.target.value),
                        }
                        : item;
                }),
            },
        }));
    }, [state.selected.saleItems]);

    const handleSetSelectedComment = useCallback((value: string): void => {
        setState((prevState: State): State => ({
            ...prevState,
            selected: {
                ...prevState.selected,
                comment: value,
            },
        }));
    }, []);

    const handleSetSelectedDate = useCallback((value: Date): void => {
        setState((prevState: State): State => ({
            ...prevState,
            selected: {
                ...prevState.selected,
                date: value,
            },
        }));
    }, []);

    const handleSetSelectedDeliveryAddress = useCallback((value: DeliveryAddressEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            selected: {
                ...prevState.selected,
                deliveryAddress: value,
            },
        }));
    }, []);

    const handleSetSelectedDeliveryMethod = useCallback((value: DeliveryMethodEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            selected: {
                ...prevState.selected,
                deliveryMethod: value,
            },
        }));
    }, []);

    const handleSetSelectedPaymentMethod = useCallback((value: PaymentMethodEntity | null): void => {
        setState((prevState: State): State => ({
            ...prevState,
            selected: {
                ...prevState.selected,
                paymentMethod: value,
            },
        }));
    }, []);

    const handleSetTranscribedFromProductIsOpened = useCallback((value: boolean) => {
        setState((prevState: State): State => ({
            ...prevState,
            transcribedFromProductIsOpened: value,
        }));
    }, []);

    const handleSetTranscribedFromProduct = useCallback((value: ProductEntity | ServiceListEntity) => {
        handleClickProduct(value);
    }, [handleClickProduct]);

    // --------------------------------------------------------------Styles---
    const gridStyles: CSSPropertiesModel = {
        main: {
            height: 700,
        },
        subLeft: {
            flexDirection: 'column',
            flexWrap: 'nowrap',
            gap: 8,
            height: '100%',
            padding: 8,
        },
        subRight: {
            borderLeftColor: 'rgba(255, 255, 255, 0.12)',
            borderLeftStyle: 'solid',
            borderLeftWidth: 1,
            flexDirection: 'column',
            flexWrap: 'nowrap',
            gap: 8,
            height: '100%',
            padding: 8,
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Grid
            container
            style={gridStyles.main}
        >
            <Grid
                item container
                style={gridStyles.subLeft}
                lg={9} md={8} sm={6} xs={12}
            >
                <SubGroupListComponent
                    callbackGroupFilter={handleClickGroupFilter}
                    filteredGroupId={state.filterState.groupId}
                    groups={state.groups}
                />

                <SubProductFilteringComponent
                    callbackChangeBarcodeFilter={handleChangeBarcodeFilter}
                    callbackChangeNameFilter={handleChangeNameFilter}
                    filteredBarcode={state.filterState.barcode}
                    filteredName={state.filterState.name}
                />

                <SubProductListComponent
                    callbackClickProduct={handleClickProduct}
                    products={state.products}
                    theme={theme}
                />

                <SubSaleItemsSummaryComponent
                    selectedDeliveryMethod={state.selected.deliveryMethod}
                    selectedSaleItems={state.selected.saleItems}
                />
            </Grid>
            <Grid
                item container
                component={Paper}
                elevation={4}
                style={gridStyles.subRight}
                lg={3} md={4} sm={6} xs={12}
            >
                <SubSaleItemListHeaderComponent />

                <SubSaleItemListComponent
                    callbackChangeQuantity={handleChangeQuantity}
                    callbackClickAdd={handleClickAdd}
                    callbackClickDelete={handleClickDelete}
                    callbackClickRemove={handleClickRemove}
                    selectedSaleItems={state.selected.saleItems}
                />

                <SubSaleItemListFooterComponent
                    callbackSelectedComment={handleSetSelectedComment}
                    callbackSelectedDate={handleSetSelectedDate}
                    callbackSelectedDeliveryAddress={handleSetSelectedDeliveryAddress}
                    callbackSelectedDeliveryMethod={handleSetSelectedDeliveryMethod}
                    callbackSelectedPaymentMethod={handleSetSelectedPaymentMethod}
                    deliveryMethods={state.deliveryMethods}
                    partners={state.partners}
                    paymentMethods={state.paymentMethods}
                    selectedComment={state.selected.comment}
                    selectedDate={state.selected.date}
                    selectedDeliveryAddress={state.selected.deliveryAddress}
                    selectedDeliveryMethod={state.selected.deliveryMethod}
                    selectedPaymentMethod={state.selected.paymentMethod}
                    selectedSaleItems={state.selected.saleItems}
                />
            </Grid>

            <SubDialogTranscribedFromProductComponent
                callbackIsOpened={handleSetTranscribedFromProductIsOpened}
                callbackSelectedProduct={handleSetTranscribedFromProduct}
                groups={state.groups}
                isOpened={state.transcribedFromProductIsOpened}
                unitOfMeasures={state.unitOfMeasures}
                vatRates={state.vatRates}
            />
        </Grid>
    );
}

// -----------------------------------------------------------------Exports---
export interface SaleItem {
    product: ProductEntity | ServiceListEntity;
    quantity: number;
}

export default SaleInvoicePage;
