// ----------------------------------------------------------------Privates---
interface PartnerAddOrUpdateRequestModel {
    name?: string;
    postCode?: string;
    city?: string;
    streetName?: string;
    streetType?: string;
    houseNumber?: string;
    taxNumber?: string;
    bankAccountNumber?: string;
    telephone?: string;
    email?: string;
    contactName?: string;
    contactTelephone?: string;
    contactEmail?: string;
    comment?: string;
    countryId?: number;
    type?: string;
}

// -----------------------------------------------------------------Exports---
export const initialPartnerAddOrUpdateRequestModel: PartnerAddOrUpdateRequestModel = {
    name: '',
    postCode: '',
    city: '',
    streetName: '',
    streetType: '',
    houseNumber: '',
    taxNumber: '',
    bankAccountNumber: '',
    telephone: '',
    email: '',
    contactName: '',
    contactTelephone: '',
    contactEmail: '',
    comment: '',
    countryId: undefined,
    type: undefined,
}

export default PartnerAddOrUpdateRequestModel;
