// ----------------------------------------------------------------Privates---
interface UnitOfMeasureFilterRequestModel {
    id?: number;
    createdAtStart?: Date | null;
    createdAtEnd?: Date | null;
    updatedAtStart?: Date | null;
    updatedAtEnd?: Date | null;
    createdBy?: number;
    updatedBy?: number;
    shortName?: string;
    longName?: string;
    navName?: string | null;
    isDecompasable?: boolean | null;
}

// -----------------------------------------------------------------Exports---
export const initialUnitOfMeasureFilterRequestModel: UnitOfMeasureFilterRequestModel = {
    id: -1,
    createdAtStart: null,
    createdAtEnd: null,
    updatedAtStart: null,
    updatedAtEnd: null,
    createdBy: undefined,
    updatedBy: undefined,
    shortName: '',
    longName: '',
    navName: null,
    isDecompasable: null,
}

export default UnitOfMeasureFilterRequestModel;
