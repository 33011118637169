// -----------------------------------------------------------------Imports---
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { Slide } from '@mui/material';

import { TransitionProps } from '@mui/material/transitions';

// ----------------------------------------------------------------Privates---
const Transition = (
    props: TransitionProps & { children: ReactElement<any, any>; },
    ref: Ref<unknown>,
): JSX.Element => {
    return (
        <Slide direction={'up'} ref={ref} {...props} />
    );
}

const DialogTransitionComponent: ForwardRefExoticComponent<TransitionProps & { children: ReactElement<any, any>; } & RefAttributes<unknown>> = forwardRef(Transition);

// -----------------------------------------------------------------Exports---
export default DialogTransitionComponent;
