// -----------------------------------------------------------------Imports---
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    SyntheticEvent,
    useCallback,
    useState,
} from 'react';

import { toast } from 'react-toastify';

import {
    Close,
    Save,
} from '@mui/icons-material';

import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Switch,
    TextField,
    Theme,
    useTheme,
} from '@mui/material';

import {
    grossFromNet,
    netFromGross,
    numberInStringIsValid,
    roundTo,
} from '../../../../CodeKit';

import DialogTransitionComponent from '../../../../components/dialog/DialogTransitionComponent';

import GroupEntity from '../../../../entities/GroupEntity';
import ProductEntity from '../../../../entities/ProductEntity';
import ServiceListEntity from '../../../../entities/ServiceListEntity';
import UnitOfMeasureEntity from '../../../../entities/UnitOfMeasureEntity';
import VatRateEntity from '../../../../entities/VatRateEntity';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackIsOpened: (value: boolean) => void;
    callbackSelectedProduct: (value: ProductEntity | ServiceListEntity) => void;
    isOpened: boolean;
    groups: GroupEntity[];
    unitOfMeasures: UnitOfMeasureEntity[];
    vatRates: VatRateEntity[];
}

const initialThisProduct: ProductEntity | ServiceListEntity = {
    barcode: 'SB0M',
    name: '',
    serviceListNumber: '',
    group: undefined,
    vatRate: undefined,
    unitOfMeasure: undefined,
    netSalesPrice: 0,
    grossSalesPrice: 0,
    isMediatedService: false,
}

/**
 * Átírható termék
 * @param callbackIsOpened Nyitott állapot esemény
 * @param callbackSelectedProduct Kiválasztott átírható termék esemény
 * @param isOpened Nyitott állapot
 * @param groups Csoportok
 * @param unitOfMeasures Mennyiségi egységek
 * @param vatRates ÁFAkulcsok
 */
const SubDialogTranscribedFromProductComponent = ({
    callbackIsOpened,
    callbackSelectedProduct,
    isOpened,
    groups,
    unitOfMeasures,
    vatRates,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [thisProduct, setThisProduct]: [ProductEntity | ServiceListEntity, Dispatch<SetStateAction<ProductEntity | ServiceListEntity>>] = useState<ProductEntity | ServiceListEntity>(initialThisProduct);

    const validateData = useCallback((): boolean => {
        if (
            (!thisProduct.name)
            || ((thisProduct.name)
                && (thisProduct.name.length === 0))
        ) {
            toast.error('"Megnevezés" mező kitöltése kötelező.');
            return false;
        }

        if (!thisProduct.group) {
            toast.error('"Csoport" érték kiválasztása kötelező.');
            return false;
        }

        if (!thisProduct.vatRate) {
            toast.error('"ÁFAkulcs" érték kiválasztása kötelező.');
            return false;
        }

        if (!thisProduct.unitOfMeasure) {
            toast.error('"Mennyiségi egység" érték kiválasztása kötelező.');
            return false;
        }

        if ((!thisProduct.grossSalesPrice)
            || (thisProduct.grossSalesPrice <= 0)) {
            toast.error('"Eladási bruttó ár" nem lehet kisebb vagy egyenlő nullánál.');
            return false;
        }

        return true;
    }, [
        thisProduct.grossSalesPrice,
        thisProduct.group,
        thisProduct.name,
        thisProduct.unitOfMeasure,
        thisProduct.vatRate,
    ]);

    // --------------------------------------------------------------Events---
    const handleChangeNameDialog = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setThisProduct((prevThisProduct: ProductEntity | ServiceListEntity): ProductEntity | ServiceListEntity => ({
            ...prevThisProduct,
            name: event.target.value,
        }));
    }, []);

    const handleChangeServiceListNumberDialog = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setThisProduct((prevThisProduct: ProductEntity | ServiceListEntity): ProductEntity | ServiceListEntity => ({
            ...prevThisProduct,
            serviceListNumber: event.target.value,
        }));
    }, []);

    const handleChangeGroupDialog = useCallback((event: SyntheticEvent, newValue: GroupEntity | null): void => {
        setThisProduct((prevThisProduct: ProductEntity | ServiceListEntity): ProductEntity | ServiceListEntity => ({
            ...prevThisProduct,
            group: newValue ?? undefined,
        }));
    }, []);

    const handleChangeVatRateDialog = useCallback((event: SyntheticEvent, newValue: VatRateEntity | null): void => {
        const netSalesPrice: number = thisProduct.netSalesPrice ?? 0;
        let grossSalesPrice: number = thisProduct.grossSalesPrice ?? 0;

        if (newValue) {
            grossSalesPrice = grossFromNet(netSalesPrice, newValue?.value);
        }

        setThisProduct((prevThisProduct: ProductEntity | ServiceListEntity): ProductEntity | ServiceListEntity => ({
            ...prevThisProduct,
            vatRate: newValue ?? undefined,
            grossSalesPrice: roundTo(grossSalesPrice),
        }));
    }, [
        thisProduct.grossSalesPrice,
        thisProduct.netSalesPrice
    ]);

    const handleChangeUnitOfMeasureDialog = useCallback((event: SyntheticEvent, newValue: UnitOfMeasureEntity | null): void => {
        setThisProduct((prevThisProduct: ProductEntity | ServiceListEntity): ProductEntity | ServiceListEntity => ({
            ...prevThisProduct,
            unitOfMeasure: newValue ?? undefined,
        }));
    }, []);

    const handleChangeNetSalesPriceDialog = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        const netSalesPrice: number = Number(event.target.value);
        let grossSalesPrice: number = thisProduct?.grossSalesPrice ?? 0;

        if (thisProduct?.vatRate) {
            grossSalesPrice = grossFromNet(netSalesPrice, thisProduct?.vatRate?.value);
        }

        setThisProduct((prevThisProduct: ProductEntity | ServiceListEntity): ProductEntity | ServiceListEntity => ({
            ...prevThisProduct,
            netSalesPrice: roundTo(netSalesPrice),
            grossSalesPrice: roundTo(grossSalesPrice),
        }));
    }, [
        thisProduct?.grossSalesPrice,
        thisProduct?.vatRate
    ]);

    const handleChangeGrossSalesPriceDialog = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0)
            && (Number(event.target.value) <= 0)) {
            return;
        }

        const grossSalesPrice: number = Number(event.target.value);
        let netSalesPrice: number = thisProduct?.netSalesPrice ?? 0;

        if (thisProduct?.vatRate) {
            netSalesPrice = netFromGross(grossSalesPrice, thisProduct?.vatRate?.value!);
        }

        setThisProduct((prevThisProduct: ProductEntity | ServiceListEntity): ProductEntity | ServiceListEntity => ({
            ...prevThisProduct,
            netSalesPrice: roundTo(netSalesPrice),
            grossSalesPrice: roundTo(grossSalesPrice),
        }));
    }, [
        thisProduct?.netSalesPrice,
        thisProduct?.vatRate
    ]);

    const handleChangeIsMediatedServiceDialog = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setThisProduct((prevThisProduct: ProductEntity | ServiceListEntity): ProductEntity | ServiceListEntity => ({
            ...prevThisProduct,
            isMediatedService: event.target.checked,
        }));
    }, []);

    const handleClickClose = useCallback((): void => {
        setThisProduct(initialThisProduct);
        callbackIsOpened(false);
    }, [callbackIsOpened]);

    const handleClickSave = useCallback((): void => {
        if (!validateData()) {
            return;
        }

        callbackSelectedProduct(thisProduct);
        setThisProduct(initialThisProduct);
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        callbackSelectedProduct,
        thisProduct,
        validateData,
    ]);

    // --------------------------------------------------------------Styles---
    const dialogActionsStyles: CSSPropertiesModel = {
        main: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }

    const dialogContentStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
        },
    }

    const dialogTitleStyles: CSSPropertiesModel = {
        main: {
            textAlign: 'center',
            userSelect: 'none',
        },
    }

    const paperStyles: CSSPropertiesModel = {
        main: {
            maxWidth: 'initial',
        },
    }

    const paperSx: SxPropsModel = {
        main: {
            width: '50%',
            [theme.breakpoints.down('lg')]: {
                width: '75%',
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        }
    }

    // --------------------------------------------------------------Return---
    return (
        <Dialog
            open={isOpened}
            PaperProps={{
                style: paperStyles.main,
                sx: paperSx.main,
            }}
            TransitionComponent={DialogTransitionComponent}
        >
            <DialogTitle style={dialogTitleStyles.main}>
                Átírható termék részletek
            </DialogTitle>
            <Divider variant={'middle'} />
            <DialogContent style={dialogContentStyles.main}>
                <TextField
                    autoFocus
                    fullWidth
                    inputProps={{ maxLength: 250, }}
                    label={'Megnevezés'}
                    onChange={handleChangeNameDialog}
                    type={'text'}
                    value={thisProduct?.name}
                />
                <TextField
                    fullWidth
                    inputProps={{ maxLength: 10, }}
                    label={'SZJ kód'}
                    onChange={handleChangeServiceListNumberDialog}
                    type={'text'}
                    value={thisProduct?.serviceListNumber}
                />
                <Autocomplete
                    autoComplete
                    filterSelectedOptions
                    fullWidth
                    getOptionLabel={(option: GroupEntity) => `${option.name} (${option.id?.toString()})` ?? ''}
                    onChange={handleChangeGroupDialog}
                    options={groups}
                    renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Csoport'} />}
                    value={thisProduct?.group ?? null}
                />
                <Autocomplete
                    autoComplete
                    filterSelectedOptions
                    fullWidth
                    getOptionLabel={(option: VatRateEntity) => `${option.name} (${option.id?.toString()})` ?? ''}
                    onChange={handleChangeVatRateDialog}
                    options={vatRates}
                    renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'ÁFAkulcs'} />}
                    value={thisProduct?.vatRate ?? null}
                />
                <Autocomplete
                    autoComplete
                    filterSelectedOptions
                    fullWidth
                    getOptionLabel={(option: UnitOfMeasureEntity) => `${option.shortName} (${option.id?.toString()})` ?? ''}
                    onChange={handleChangeUnitOfMeasureDialog}
                    options={unitOfMeasures}
                    renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} label={'Mennyiségi egység'} />}
                    value={thisProduct?.unitOfMeasure ?? null}
                />
                <TextField
                    fullWidth
                    label={'Eladási nettó ár'}
                    onChange={handleChangeNetSalesPriceDialog}
                    type={'number'}
                    value={thisProduct?.netSalesPrice}
                />
                <TextField
                    fullWidth
                    label={'Eladási bruttó ár'}
                    onChange={handleChangeGrossSalesPriceDialog}
                    type={'number'}
                    value={thisProduct?.grossSalesPrice}
                />
                <FormControlLabel
                    control={<Switch checked={thisProduct?.isMediatedService} onChange={handleChangeIsMediatedServiceDialog} />}
                    label={thisProduct?.isMediatedService ? 'Közvetített szolgáltatás' : 'Saját'}
                />
            </DialogContent>
            <Divider variant={'middle'} />
            <DialogActions style={dialogActionsStyles.main}>
                <Button
                    onClick={handleClickClose}
                    startIcon={<Close />}
                    variant={'outlined'}
                >
                    Mégsem
                </Button>
                <Button
                    color={'success'}
                    onClick={handleClickSave}
                    startIcon={<Save />}
                    variant={'contained'}
                >
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// -----------------------------------------------------------------Exports---
export default SubDialogTranscribedFromProductComponent;
