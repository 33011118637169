// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import ProductEntity from '../../entities/ProductEntity';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import ProductAddOrUpdateRequestModel from '../../models/product/ProductAddOrUpdateRequestModel';
import ProductListRequestModel from '../../models/product/ProductListRequestModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async getProducts(data?: ProductListRequestModel): Promise<ResponseModel<ProductEntity[]>> {
        if (!data) {
            data = {};
        }

        return await fetchData(
            getApiUrlBase() + getApiRoutes.product.getProducts,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async addProduct(data: ProductAddOrUpdateRequestModel): Promise<ResponseModel<ProductEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.product.addProduct,
            'POST',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async updateProduct(id: number, data?: ProductAddOrUpdateRequestModel): Promise<ResponseModel<ProductEntity>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.product.updateProduct.replace('{id}', id.toString()),
            'PUT',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        );
    }

    async removeProduct(id: number): Promise<ResponseModel<string>> {
        return await fetchData(
            getApiUrlBase() + getApiRoutes.product.removeProduct.replace('{id}', id.toString()),
            'DELETE',
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        );
    }
}

const ProductService = new LocalService();

// -----------------------------------------------------------------Exports---
export default ProductService;
