// ----------------------------------------------------------------Privates---
interface VatRateFilterRequestModel {
    id?: number;
    createdAtStart?: Date | null;
    createdAtEnd?: Date | null;
    updatedAtStart?: Date | null;
    updatedAtEnd?: Date | null;
    createdBy?: number;
    updatedBy?: number;
    value?: number;
    name?: string;
}

// -----------------------------------------------------------------Exports---
export const initialVatRateFilterRequestModel: VatRateFilterRequestModel = {
    id: -1,
    createdAtStart: null,
    createdAtEnd: null,
    updatedAtStart: null,
    updatedAtEnd: null,
    createdBy: undefined,
    updatedBy: undefined,
    value: -1,
    name: '',
}

export default VatRateFilterRequestModel;
