// ----------------------------------------------------------------Privates---
interface VatRateAddOrUpdateRequestModel {
    value?: number;
    name?: string;
}

// -----------------------------------------------------------------Exports---
export const initialVatRateAddOrUpdateRequestModel: VatRateAddOrUpdateRequestModel = {
    value: 0,
    name: '',
}

export default VatRateAddOrUpdateRequestModel;
