// -----------------------------------------------------------------Imports---
import fetchData from '../AxiosService';

import jwtDecode from 'jwt-decode';

import {
    getApiRoutes,
    getApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import LoginRequestModel from '../../models/authentication/LoginRequestModel';
import LoginResponseModel from '../../models/authentication/LoginResponseModel';

// ----------------------------------------------------------------Privates---
class LocalService {
    async login(data: LoginRequestModel): Promise<ResponseModel<LoginResponseModel>> {
        const response: ResponseModel<LoginResponseModel> = await fetchData(
            getApiUrlBase() + getApiRoutes.authentication,
            'POST',
            {},
            data,
        );

        if (response.status === 200) {
            localStorage.setItem('token', response.data?.token!);
        }

        return response;
    }

    logout(): void {
        localStorage.removeItem('token');
    }

    loggedInState(): boolean {
        if (localStorage.getItem('token') === null) {
            return false;
        }

        let expiration: Date = new Date(0);
        expiration.setUTCSeconds((jwtDecode(localStorage.getItem('token')!) as any).exp);

        return expiration <= new Date()
            ? false
            : true;
    }

    adminState(): boolean {
        return (jwtDecode(localStorage.getItem('token')!) as any).sub === '1';
    }
}

const AuthenticationService = new LocalService();

// -----------------------------------------------------------------Exports---
export default AuthenticationService;
