// -----------------------------------------------------------------Imports---
import moment from 'moment';

import {
    Dispatch,
    SetStateAction,
    useCallback,
    useState,
} from 'react';

import {
    Close,
    Save,
} from '@mui/icons-material';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Theme,
    useTheme,
} from '@mui/material';

import {
    DateTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
    dateTimeFormat,
    dateTimePickerInputFormats,
} from '../../../../Global';

import DialogTransitionComponent from '../../../../components/dialog/DialogTransitionComponent';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';
import SxPropsModel from '../../../../models/SxPropsModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackIsOpened: (value: boolean) => void;
    callbackSelectedDate: (value: Date) => void;
    isOpened: boolean;
    selectedDate: Date;
}

/**
 * Számla teljesítési idő
 * @param callbackIsOpened Nyitott állapot esemény
 * @param callbackSelectedDate Kiválasztott időbélyeg esemény
 * @param isOpened Nyitott állapot
 * @param selectedDate Kiválasztott időbélyeg
 */
const SubDialogDateComponent = ({
    callbackIsOpened,
    callbackSelectedDate,
    isOpened,
    selectedDate,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [thisDate, setThisDate]: [Date | null, Dispatch<SetStateAction<Date | null>>] = useState<Date | null>((moment(selectedDate) as unknown) as Date);

    // --------------------------------------------------------------Events---
    const handleChangeDate = useCallback((event: Date | null): void => {
        setThisDate(event);
    }, []);

    const handleClickClose = useCallback((): void => {
        setThisDate((moment(selectedDate) as unknown) as Date);
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        selectedDate,
    ]);

    const handleClickSave = useCallback((): void => {
        if ((thisDate !== null) && (moment(thisDate).isValid())) {
            callbackSelectedDate(thisDate);
        }
        callbackIsOpened(false);
    }, [
        callbackIsOpened,
        callbackSelectedDate,
        thisDate,
    ]);

    // --------------------------------------------------------------Styles---
    const dialogActionsStyles: CSSPropertiesModel = {
        main: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }

    const dialogContentStyles: CSSPropertiesModel = {
        main: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
        },
    }

    const dialogTitleStyles: CSSPropertiesModel = {
        main: {
            textAlign: 'center',
            userSelect: 'none',
        },
    }

    const paperStyles: CSSPropertiesModel = {
        main: {
            maxWidth: 'initial',
        },
    }

    const paperSx: SxPropsModel = {
        main: {
            width: '50%',
            [theme.breakpoints.down('lg')]: {
                width: '75%',
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Dialog
            open={isOpened}
            PaperProps={{
                style: paperStyles.main,
                sx: paperSx.main,
            }}
            TransitionComponent={DialogTransitionComponent}
        >
            <DialogTitle style={dialogTitleStyles.main}>
                Teljesítési idő kiválasztás
            </DialogTitle>
            <Divider variant={'middle'} />
            <DialogContent style={dialogContentStyles.main}>
                <LocalizationProvider adapterLocale={'hu'} dateAdapter={AdapterMoment}>
                    <DateTimePicker ampm={false} autoFocus format={dateTimeFormat} label={'Teljesítés ideje'} onChange={handleChangeDate} slotProps={{ textField: { fullWidth: true, } }} value={thisDate} views={dateTimePickerInputFormats} />
                </LocalizationProvider>
            </DialogContent>
            <Divider variant={'middle'} />
            <DialogActions style={dialogActionsStyles.main}>
                <Button
                    onClick={handleClickClose}
                    startIcon={<Close />}
                    variant={'outlined'}
                >
                    Mégsem
                </Button>
                <Button
                    color={'success'}
                    disabled={(thisDate === null) || (!moment(thisDate).isValid())}
                    onClick={handleClickSave}
                    startIcon={<Save />}
                    variant={'contained'}
                >
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// -----------------------------------------------------------------Exports---
export default SubDialogDateComponent;
