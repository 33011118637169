// -----------------------------------------------------------------Imports---
import moment from 'moment';

import {
    Dispatch,
    SetStateAction,
    useCallback,
    useState,
} from 'react';

import {
    Button,
    Divider,
    Grid,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';

import SubDialogCommentComponent from './SubDialogCommentComponent';
import SubDialogDateComponent from './SubDialogDateComponent';
import SubDialogDeliveryAddressComponent from './SubDialogDeliveryAddressComponent';
import SubDialogDeliveryMethodComponent from './SubDialogDeliveryMethodComponent';
import SubDialogPaymentMethodComponent from './SubDialogPaymentMethodComponent';

import { SaleItem } from '../SaleInvoicePage';

import { dateTimeFormat } from '../../../../Global';

import DeliveryAddressEntity from '../../../../entities/DeliveryAddressEntity';
import DeliveryMethodEntity from '../../../../entities/DeliveryMethodEntity';
import PartnerEntity from '../../../../entities/PartnerEntity';
import PaymentMethodEntity from '../../../../entities/PaymentMethodEntity';

import ThemeModeEnumerator from '../../../../enumerators/ThemeModeEnumerator';

import CSSPropertiesModel from '../../../../models/CSSPropertiesModel';

// ----------------------------------------------------------------Privates---
interface Props {
    callbackSelectedComment: (value: string) => void;
    callbackSelectedDate: (value: Date) => void;
    callbackSelectedDeliveryAddress: (value: DeliveryAddressEntity | null) => void;
    callbackSelectedDeliveryMethod: (value: any) => void;
    callbackSelectedPaymentMethod: (value: any) => void;
    deliveryMethods: DeliveryMethodEntity[],
    partners: PartnerEntity[];
    paymentMethods: PaymentMethodEntity[],
    selectedComment: string;
    selectedDate: Date;
    selectedDeliveryAddress: DeliveryAddressEntity | null;
    selectedDeliveryMethod: DeliveryMethodEntity | null;
    selectedPaymentMethod: PaymentMethodEntity | null;
    selectedSaleItems: SaleItem[];
}

interface State {
    commentDialogIsOpened: boolean;
    dateDialogIsOpened: boolean;
    deliveryAddressDialogIsOpened: boolean;
    deliveryMethodDialogIsOpened: boolean;
    paymentMethodDialogIsOpened: boolean;
}

const initialState: State = {
    commentDialogIsOpened: false,
    dateDialogIsOpened: false,
    deliveryAddressDialogIsOpened: false,
    deliveryMethodDialogIsOpened: false,
    paymentMethodDialogIsOpened: false,
}

/**
 * Kiválasztott számla termékek láb sor
 * @param callbackSelectedComment Kiválasztott megjegyzés esemény
 * @param callbackSelectedDate Kiválasztott teljesítés időbélyeg esemény
 * @param callbackSelectedDeliveryAddress Kiválasztott szállítási cím esemény
 * @param callbackSelectedDeliveryMethod Kiválasztott szállítási mód esemény
 * @param callbackSelectedPaymentMethod Kiválasztott fizetési mód esemény
 * @param deliveryMethods Szállítási módok
 * @param partners Partnerek
 * @param paymentMethods Fizetési módok
 * @param selectedComment Kiválasztott megjegyzés
 * @param selectedDate Kiválasztott teljesítés időbélyeg
 * @param selectedDeliveryAddress Kiválasztott szállítási cím
 * @param selectedDeliveryMethod Kiválasztott szállítási mód
 * @param selectedPaymentMethod Kiválasztott fizetési mód
 * @param selectedSaleItems Kiválasztott termékek
 */
const SubSaleItemListFooterComponent = ({
    callbackSelectedComment,
    callbackSelectedDate,
    callbackSelectedDeliveryAddress,
    callbackSelectedDeliveryMethod,
    callbackSelectedPaymentMethod,
    deliveryMethods,
    partners,
    paymentMethods,
    selectedComment,
    selectedDate,
    selectedDeliveryAddress,
    selectedDeliveryMethod,
    selectedPaymentMethod,
    selectedSaleItems,
}: Props): JSX.Element => {
    // ------------------------------------------------------------Privates---
    const theme: Theme = useTheme<Theme>();
    const [state, setState]: [State, Dispatch<SetStateAction<State>>] = useState<State>(initialState);

    // --------------------------------------------------------------Events---
    const handleClickDeliveryMethod = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryMethodDialogIsOpened: !prevState.deliveryMethodDialogIsOpened,
        }));
    }, []);

    const handleClickPaymentMethod = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            paymentMethodDialogIsOpened: !prevState.paymentMethodDialogIsOpened,
        }));
    }, []);

    const handleClickDeliveryAddress = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressDialogIsOpened: !prevState.deliveryAddressDialogIsOpened,
        }));
    }, []);

    const handleClickComment = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            commentDialogIsOpened: !prevState.commentDialogIsOpened,
        }));
    }, []);

    const handleClickDate = useCallback((): void => {
        setState((prevState: State): State => ({
            ...prevState,
            dateDialogIsOpened: !prevState.dateDialogIsOpened,
        }));
    }, []);

    const handleSetDeliveryMethodDialogIsOpened = useCallback((value: boolean) => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryMethodDialogIsOpened: value,
        }));
    }, []);

    const handleSetPaymentMethodDialogIsOpened = useCallback((value: boolean) => {
        setState((prevState: State): State => ({
            ...prevState,
            paymentMethodDialogIsOpened: value,
        }));
    }, []);

    const handleSetDeliveryAddressDialogIsOpened = useCallback((value: boolean) => {
        setState((prevState: State): State => ({
            ...prevState,
            deliveryAddressDialogIsOpened: value,
        }));
    }, []);

    const handleSetCommentDialogIsOpened = useCallback((value: boolean) => {
        setState((prevState: State): State => ({
            ...prevState,
            commentDialogIsOpened: value,
        }));
    }, []);

    const handleSetDateDialogIsOpened = useCallback((value: boolean) => {
        setState((prevState: State): State => ({
            ...prevState,
            dateDialogIsOpened: value,
        }));
    }, []);

    // --------------------------------------------------------------Styles---
    const buttonStyles: CSSPropertiesModel = {
        main: {
            textTransform: 'initial',
            wordBreak: 'break-word',
        },
    }

    const gridStyles: CSSPropertiesModel = {
        main: {
            flexDirection: 'column',
            gap: 8,
        },
    }

    const typographyStyles: CSSPropertiesModel = {
        main: {
            backgroundColor: 'secondary.main',
            borderColor: 'secondary.main',
            borderRadius: '4px',
            borderStyle: 'solid',
            borderWidth: '1px',
            color: theme.palette.mode === ThemeModeEnumerator.Dark ? 'black' : 'white',
            fontSize: '0.875rem',
            textTransform: 'uppercase',
            paddingBottom: '5px',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingTop: '5px',
            textAlign: 'center',
            userSelect: 'none',
            wordBreak: 'break-word',
        },
    }

    // --------------------------------------------------------------Return---
    return (
        <Grid
            item container
            style={gridStyles.main}
        >
            <Grid item>
                <Divider variant={'middle'} />
            </Grid>
            <Grid item>
                <Button
                    color={'primary'}
                    fullWidth
                    onClick={handleClickDeliveryMethod}
                    style={buttonStyles.main}
                    variant={selectedDeliveryMethod
                        ? 'contained'
                        : 'outlined'}
                >
                    {selectedDeliveryMethod
                        ? `${selectedDeliveryMethod.name} [${selectedDeliveryMethod.price} Ft]`
                        : 'Szállítási mód kiválasztás'}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    color={'primary'}
                    fullWidth
                    onClick={handleClickPaymentMethod}
                    style={buttonStyles.main}
                    variant={selectedPaymentMethod
                        ? 'contained'
                        : 'outlined'}
                >
                    {selectedPaymentMethod
                        ? selectedPaymentMethod.name
                        : 'Fizetési mód kiválasztás'}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    color={'primary'}
                    fullWidth
                    onClick={handleClickDeliveryAddress}
                    style={buttonStyles.main}
                    variant={selectedDeliveryAddress
                        ? 'contained'
                        : 'outlined'}
                >
                    {selectedDeliveryAddress
                        ? `${selectedDeliveryAddress.partner?.name} - ${selectedDeliveryAddress.name}`
                        : 'Szállítási cím kiválasztás'}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    color={'primary'}
                    fullWidth
                    onClick={handleClickComment}
                    style={buttonStyles.main}
                    variant={selectedComment.length
                        ? 'contained'
                        : 'outlined'}
                >
                    {selectedComment.length !== 0
                        ? selectedComment
                        : 'Megjegyzés hozzáadás'}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    color={'primary'}
                    fullWidth
                    onClick={handleClickDate}
                    style={buttonStyles.main}
                    variant={'contained'}
                >
                    {moment(selectedDate).format(dateTimeFormat)}
                </Button>
            </Grid>
            <Grid item>
                <Divider variant={'middle'} />
            </Grid>
            <Grid item>
                <Typography sx={typographyStyles.main}>
                    {`Fizetendő: ${selectedSaleItems.reduce(
                        (total: number, item: SaleItem): number =>
                            total + Number(item.product?.grossSalesPrice) * item.quantity,
                        selectedDeliveryMethod?.price ? selectedDeliveryMethod?.price : 0
                    ).toLocaleString()} Ft`}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    color={'success'}
                    disabled={(!selectedDeliveryAddress)
                        || (!selectedDeliveryMethod)
                        || (!selectedPaymentMethod)
                        || (selectedSaleItems.length === 0)
                    }
                    fullWidth
                    variant={'contained'}
                >
                    Rögzítés
                </Button>
            </Grid>

            <SubDialogDeliveryMethodComponent
                callbackIsOpened={handleSetDeliveryMethodDialogIsOpened}
                callbackSelectedDeliveryMethod={callbackSelectedDeliveryMethod}
                deliveryMethods={deliveryMethods}
                isOpened={state.deliveryMethodDialogIsOpened}
                selectedDeliveryMethod={selectedDeliveryMethod}
            />

            <SubDialogPaymentMethodComponent
                callbackIsOpened={handleSetPaymentMethodDialogIsOpened}
                callbackSelectedPaymentMethod={callbackSelectedPaymentMethod}
                isOpened={state.paymentMethodDialogIsOpened}
                paymentMethods={paymentMethods}
                selectedPaymentMethod={selectedPaymentMethod}
            />

            <SubDialogDeliveryAddressComponent
                callbackIsOpened={handleSetDeliveryAddressDialogIsOpened}
                callbackSelectedDeliveryAddress={callbackSelectedDeliveryAddress}
                partners={partners}
                isOpened={state.deliveryAddressDialogIsOpened}
                selectedDeliveryAddress={selectedDeliveryAddress}
            />

            <SubDialogCommentComponent
                callbackIsOpened={handleSetCommentDialogIsOpened}
                callbackSelectedComment={callbackSelectedComment}
                isOpened={state.commentDialogIsOpened}
                selectedComment={selectedComment}
            />

            <SubDialogDateComponent
                callbackIsOpened={handleSetDateDialogIsOpened}
                callbackSelectedDate={callbackSelectedDate}
                isOpened={state.dateDialogIsOpened}
                selectedDate={selectedDate}
            />
        </Grid>
    );
}

// -----------------------------------------------------------------Exports---
export default SubSaleItemListFooterComponent;
